<template>
  <v-container class="mb-12 pb-12">
    <v-divider
      vertical
      style="
        border-width: 1.5px;
        height: 144px;
        border-color: #fff;
        margin-left: 30px;
      "
      class="mb-16"
    ></v-divider>

    <div class="text-h1 my-12 pt-16 d-flex align-center">
      <v-img src="@/assets/images/cube.svg" max-width="50" contain class="mr-9">
      </v-img
      >{{ $t("pages.home.our_solutions.platforms.title")}}
      <v-img
        src="@/assets/images/cube.svg"
        max-width="50"
        contain
        class="ml-auto hidden-sm-and-down"
      ></v-img>
    </div>
    <template
      v-for="(platform, index) in $t(
        'pages.home.our_solutions.platforms.items'
      )"
    >
      <v-row class="py-12" :key="index" :class="`flex-row-${platform.flow}`">
        <v-col cols="12" md="6" class="hidden">
          <v-img
            height="50"
            contain
            :src="require(`@/assets/images/${platform.logo}`)"
          ></v-img>
          <div
            class="text-h4 py-12 font-weight-bold"
            v-html="platform.title"
          ></div>
          <div class="text-p" v-html="platform.text"></div>
          <div class="d-flex align-center mt-12">
            <v-btn
              :color="platform.color"
              light
              x-large
              target="_blank"
              :href="platform.link"
              >{{ platform.link_text }}
              <v-icon class="ml-9">mdi-arrow-top-right</v-icon></v-btn
            >
            <!-- <v-icon color="white" class="ml-16 mr-3" x-large
                >mdi-cellphone-link</v-icon
              > -->
            <v-img
              v-if="arrayContainsItem(platform.icons, 'desktop')"
              src="@/assets/images/desktop.svg"
              class="ml-16 mr-3"
              contain
              height="34"
              max-width="34"
            ></v-img>
            <v-img
              v-if="arrayContainsItem(platform.icons, 'laptop')"
              src="@/assets/images/laptop.svg"
              class="ml-16 mr-3"
              contain
              height="34"
              max-width="50"
            ></v-img>
            <v-img
              v-if="arrayContainsItem(platform.icons, 'phone')"
              src="@/assets/images/phone.svg"
              class="mr-3"
              contain
              height="34"
              max-width="21"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="-hidden">
          <div class="--image" :class="`--image-${platform.color} --image-${platform.flow}`">
            <div class="--figure">
              <div class="--figure-head">
                <v-img
                  src="@/assets/images/nav.svg"
                  height="10"
                  width="100"
                  contain
                  class="ma-2"
                ></v-img>
              </div>
              <v-img
                width="100%"
                class="platform-image"
                :src="require(`@/assets/images/${platform.image}`)"
              ></v-img>
            </div>
          </div>
          <div class="text-p font-weight-bold mt-12 pt-12">{{ $t("pages.home.our_solutions.recommendations") }}:</div>
          <div class="d-flex pt-3 justify-left flex-wrap" style="width: 100%">
            <v-chip v-for="item in platform.recommendations" label :color="`${platform.color} darken-4`" :key="item" class="mr-2 mb-2"># {{ item }}</v-chip>
          </div>
        </v-col>
      </v-row>
      <v-row class="py-16" :key="index">
        <v-img
          src="@/assets/images/cube.svg"
          max-width="50"
          contain
          class="my-16"
        ></v-img>
      </v-row>
    </template>
   
  </v-container>
</template>

<script>
export default {
  methods: {
    arrayContainsItem(collection, item) {
      console.log("collection", collection);
      console.log("item", item);
      if (collection.includes(item)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
* {
  word-break: normal !important;
}
.-hidden{
  @media screen and (max-width: 960px) {
    overflow: hidden;
  }
}

.solutions {
  .v-image__image {
    background-position: left center !important;
  }
  .--image {
    &.--image-orange {
      border: 2px solid #f25547;
    }
    &.--image-blue {
      border: 2px solid #7f9ebb;
    }
    border: 2px solid #f25547;
    transform: scale(1.1) translate(6%, 10%);
    border-radius: 8px;
    .--figure {
      background: #f2f2f2;
      display: block;
      border-radius: 6px;
      transform: translate(10px, -10px);
    }
    .--figure-head {
      padding: 5px;
      & + * {
        border-radius: 0 0 6px 6px;
      }
    } // .platform-image {
    //   transform: translate(10px, -10px);
    //   border-radius: 5px;
    // }
    &.--image-reverse {
      transform: scale(1.1) translate(-6%, 10%);
      .--figure {
        transform: translate(-10px, -10px);
      }
    }
  }
}
</style>