<template>
  <div class="--tools">
    <v-container class="mb-12 pb-12">
      <v-divider
        vertical
        style="
          border-width: 1.5px;
          height: 144px;
          border-color: #fff;
          margin-left: 30px;
        "
        class="mb-16"
      ></v-divider>

      <div class="text-h1 my-12 pt-16 d-flex align-center">
        <v-img
          src="@/assets/images/cube.svg"
          max-width="50"
          contain
          class="mr-9"
        >
        </v-img
        >{{ $t('pages.home.our_solutions.tools.title')}}
        <v-img
          src="@/assets/images/cube.svg"
          max-width="50"
          contain
          class="ml-auto hidden-sm-and-down"
        ></v-img>
      </div>
      <v-row class="mt-12">
        <v-col>
          <template v-for="(item, index) in $t('pages.home.our_solutions.tools.items')">
            <v-card
              :color="item.color"
              class="pa-6 mb-9"
              v-if="!isOdd(index)"
              :key="index"
            >
              <v-card-title
                ><v-avatar class="--custom-icon" size="64" color="white" tile
                  ><v-icon color="black" large
                    >mdi-{{ item.icon }}</v-icon
                  ></v-avatar
                ></v-card-title
              >
              <v-card-title v-html="item.title"></v-card-title>
              <v-card-text v-html="item.short_description"></v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="open(item)"
                  >{{ $t("pages.home.our_solutions.know_more")}}
                  <v-icon class="ml-4">mdi-arrow-right-circle</v-icon></v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
        <v-col style="padding-top: 180px">
          <template v-for="(item, index) in $t('pages.home.our_solutions.tools.items')">
            <v-card
              :color="item.color"
              class="pa-6 mb-9"
              v-if="isOdd(index)"
              :key="index"
            >
              <v-card-title
                ><v-avatar class="--custom-icon" size="64" color="white" tile
                  ><v-icon color="black" large
                    >mdi-{{ item.icon }}</v-icon
                  ></v-avatar
                ></v-card-title
              >
              <v-card-title v-html="item.title"></v-card-title>
              <v-card-text v-html="item.short_description"></v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="open(item)"
                  >{{ $t("pages.home.our_solutions.know_more")}}
                  <v-icon class="ml-4">mdi-arrow-right-circle</v-icon></v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="720">
      <v-card :color="active.color" v-if="active" class="px-10 py-12">
        <v-card-title
          v-if="active.title"
          class="flex-column justify-start align-start"
        >
          <v-avatar
            class="--custom-icon mb-9"
            block
            size="64"
            color="white"
            tile
            ><v-icon color="black" large
              >mdi-{{ active.icon }}</v-icon
            ></v-avatar
          >
          {{ active.title }}

          <v-btn class="--close" icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          v-if="active.description"
          v-html="active.description"
        ></v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: null,
      dialog: false,
    };
  },
  methods: {
    isOdd(num) {
      return num % 2;
    },
    open(item) {
      this.active = item;
      this.dialog = true;
    },
  },
  watch: {
    //   active: {
    //       deep: true,
    //       handler(value){
    //           const keys = Object.keys(value)
    //           if(keys.length > 0){
    //               this.dialog = true
    //           }
    //         //   else{
    //         //       this.dialog = false
    //         //   }
    //       }
    //   },
    dialog(value) {
      if (!value) {
        this.active = null;
      }
    },
  },
};
</script>

<style lang="scss">
* {
  word-break: normal !important;
}
.--tools {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(35, 58, 117, 1) 400px,
    rgba(35, 58, 117, 1) calc(100% - 400px),
    rgba(0, 0, 0, 1) 100%
  );
}
.solutions {
  .v-image__image {
    background-position: left center !important;
  }
}
.--custom-icon {
  position: relative;
}
.--close {
  position: absolute;
  right: 5px;
  top: 5px;
}
.v-card br {
  content: "";
  margin: 4em;
  display: block;
  font-size: 24%;
}
</style>