<template>
  <v-app-bar
    class="transition-swing"
    app
    elevation="0"
    :style="styling"
    :position="position"
    :hide-on-scroll="hideOnScroll"
  >
    <!-- :light="dark" -->
    <div class="d-flex align-center">
      <v-btn
        tile
        x-large
        class="pa-0 ma-0 mt-n2 mr-12 logo"
        light
        elevation="0"
        to="/"
        style="height: 50px !important; background-color: rgba(0, 0, 0, 0)"
      >
        <v-img
          to="/"
          alt="Vuetify Logo"
          contain
          src="@/assets/images/logo.svg"
          height="60"
        ></v-img>
      </v-btn>
    </div>

    <!-- <v-spacer></v-spacer> -->

    <div
      class="d-flex justify-md-space-between justify-end align-center --menu"
    >
      <v-btn to="/about" text class="hidden-sm-and-down">
        {{ $t("nav.about") }}
      </v-btn>
      <div style="position: relative" class="hidden-sm-and-down">
        <v-menu bottom style="top: 54px !important">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              {{ $t("nav.solutions") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item href="https://canaldoanuncio.com" target="_blank">
              <v-list-item-content>
                <v-list-item-title
                  >Canal do Anúncio
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://canaldomidia.com" target="_blank">
              <v-list-item-content>
                <v-list-item-title
                  >Canal do Mídia
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              href="https://franquias.canaldoanuncio.com"
              target="_blank"
            >
              <v-list-item-content>
                <v-list-item-title
                  >Franquias
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://eugencias.com/" target="_blank">
              <v-list-item-content>
                <v-list-item-title
                  >In Houses
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              href="https://app.canaldoanuncio.com/portal-do-veiculo"
              target="_blank"
            >
              <v-list-item-content>
                <v-list-item-title
                  >Veículos de Comunicação
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list></v-menu
        >
      </div>

      <v-btn to="/media-insiders" text class="hidden-sm-and-down">
        {{ $t("nav.media-insiders") }}
      </v-btn>
      <v-btn to="/careers" text class="hidden-sm-and-down">
        {{ $t("nav.careers") }}
      </v-btn>
      <v-btn to="/investors" text class="hidden-sm-and-down">
        {{ $t("nav.investors") }}
      </v-btn>
      <v-btn to="/contact-us" text class="hidden-sm-and-down">
        {{ $t("nav.contact-us") }}
      </v-btn>
      <v-btn
        href="https://store.themediatrade.com"
        color="white"
        light
        target="_blank"
        class="hidden-sm-and-down"
      >
        <v-icon left class="ml-n1">mdi-shopping</v-icon>
        {{ $t("nav.store") }}
        <v-icon small right class="ml-3">mdi-arrow-top-right</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on"
            ><v-img
              :src="langs[$i18n.locale]"
              height="20"
              width="30"
              contain
            ></v-img>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(lang, i) in langs"
            :key="i"
            @click="changeLang(i)"
          >
            <v-list-item-title class="d-flex align-center"
              ><v-img
                :src="lang"
                height="14"
                width="21"
                class="mr-3"
                contain
              ></v-img
              ><span style="text-transform: uppercase">{{
                i
              }}</span></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-app-bar-nav-icon
        @click="$emit('toggle', true)"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
    </div>
  </v-app-bar>
</template>
<script>
export default {
  name: "AppHeader",
  data() {
    return {
      langs: {
        "en-US": require("@/assets/images/countries/en-US.svg"),
        "pt-BR": require("@/assets/images/countries/pt-BR.svg"),
      },
    };
  },
  props: {
    position: String,
    hideOnScroll: Boolean,
    dark: Boolean,
  },
  created() {
    const localeDefault = this.$i18n.locale;
    const locale = this.getCookie("TMT[lang]");
    if (locale != localeDefault && locale) {
      this.changeLang(locale);
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.setCookie("TMT[lang]", lang, 180);
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  computed: {
    styling() {
      if (this.dark) return "background: #233a75; height: 70px";
      else return "background: none; height: 70px";
    },
  },
};
</script>
<style scoped lang="scss">
.--menu {
  flex: 1 1 50%;
}
</style>