<template>
  <v-container class="mb-12 pb-12">
    <v-divider
      vertical
      style="
        border-width: 1.5px;
        height: 144px;
        border-color: #fff;
        margin-left: 30px;
      "
      class="mb-16"
    ></v-divider>
    <div class="text-h1 my-12 pt-16 d-flex align-center">
      <v-img src="@/assets/images/cube.svg" max-width="50" contain class="mr-9">
      </v-img
      >{{ $t("pages.home.our_solutions.intelligence.title") }}
      <v-img
        src="@/assets/images/cube.svg"
        max-width="50"
        contain
        class="ml-auto"
      ></v-img>
    </div>
    <v-row class="mt-12">
      <v-col>
        <template
          v-for="(item, index) in $t(
            'pages.home.our_solutions.intelligence.items'
          )"
        >
          <v-card
            :color="item.color"
            class="pa-6 mb-9"
            v-if="column(index) == 1"
            :key="index"
            @click="open(item)"
          >
            <v-card-title
              ><v-avatar class="--custom-icon" size="64" color="white" tile
                ><v-icon color="black" large
                  >mdi-{{ item.icon }}</v-icon
                ></v-avatar
              ></v-card-title
            >
            <v-card-title v-html="item.title"></v-card-title>
            <v-card-text v-html="item.short_description"></v-card-text>
             <v-card-actions>
                <v-img v-if="item.logo" :src="loadSrc(item.logo)" max-width="150"></v-img>
                <div class="d-flex justify-end" style="width: 100%">
                  <v-avatar color="white" size="32">
                    <v-icon color="black">mdi-arrow-right</v-icon>
                  </v-avatar>
                </div>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
      <v-col style="padding-top: 180px">
        <template
          v-for="(item, index) in $t(
            'pages.home.our_solutions.intelligence.items'
          )"
        >
          <v-card
            :color="item.color"
            class="pa-6 mb-9"
            v-if="column(index) == 2"
            :key="index"
            @click="open(item)"
          >
            <v-card-title
              ><v-avatar class="--custom-icon" size="64" color="white" tile
                ><v-icon color="black" large
                  >mdi-{{ item.icon }}</v-icon
                ></v-avatar
              ></v-card-title
            >
            <v-card-title v-html="item.title"></v-card-title>
            <v-card-text v-html="item.short_description"></v-card-text>
             <v-card-actions>
                <v-img :src="loadSrc(item.logo)" max-width="150"></v-img>
                <div class="d-flex justify-end" style="width: 100%">
                  <v-avatar color="white" size="32">
                    <v-icon color="black">mdi-arrow-right</v-icon>
                  </v-avatar>
                </div>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
      <v-col>
        <template
          v-for="(item, index) in $t(
            'pages.home.our_solutions.intelligence.items'
          )"
        >
          <v-card
            :color="item.color"
            class="pa-6 mb-9"
            v-if="column(index) == 3"
            :key="index"
            @click="open(item)"
          >
            <v-card-title
              ><v-avatar class="--custom-icon" size="64" color="white" tile
                ><v-icon color="black" large
                  >mdi-{{ item.icon }}</v-icon
                ></v-avatar
              ></v-card-title
            >
            <v-card-title v-html="item.title"></v-card-title>
            <v-card-text v-html="item.short_description"></v-card-text>
            <v-card-actions>
                <v-img :src="loadSrc(item.logo)" max-width="150"></v-img>
                <div class="d-flex justify-end" style="width: 100%">
                  <v-avatar color="white" size="32">
                    <v-icon color="black">mdi-arrow-right</v-icon>
                  </v-avatar>
                </div>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="720">
      <v-card :color="active.color" v-if="active" class="pa-12 px-10">
        <v-card-title
          v-if="active.title"
          class="flex-column justify-start align-start"
        >
          <v-avatar
            class="--custom-icon mb-9"
            block
            size="64"
            color="white"
            tile
            ><v-icon color="black" large
              >mdi-{{ active.icon }}</v-icon
            ></v-avatar
          >
          {{ active.title }}

          <v-btn class="--close" icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          v-if="active.description"
          v-html="active.description"
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      active: null,
      dialog: false,
    };
  },
  methods: {
    loadSrc(img) {
      return require(`@/assets/images/companies/partners/${img}`);
    },
    column(num) {
      if (num == 0) {
        return 1;
      }
      let res = num % 3;
      // console.log('res', res)
      if (res == 0) {
        return 1;
      }
      if (res == 1) {
        return 3;
      }
      if (res == 2) {
        return 2;
      }
    },
    open(item) {
      this.active = item;
      this.dialog = true;
    },
  },
  watch: {
    //   active: {
    //       deep: true,
    //       handler(value){
    //           const keys = Object.keys(value)
    //           if(keys.length > 0){
    //               this.dialog = true
    //           }
    //         //   else{
    //         //       this.dialog = false
    //         //   }
    //       }
    //   },
    dialog(value) {
      if (!value) {
        this.active = null;
      }
    },
  },
};
</script>
<style lang="scss">
* {
  word-break: normal !important;
}
.solutions {
  .v-image__image {
    background-position: left center !important;
  }
}
</style>