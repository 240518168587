<template>
  <div class="d-flex align-center" :style="`position: relative;`">
    <!-- <v-img :src="type" height="100vh" width="100vw" style="position: absolute; top: 0; left: 0; opacity: .5"></v-img> -->
    <v-container class="vh-100" :style="`position: relative;`">
      <div class="d-flex align-center justify-center mb-12 pb-12">
        <div class="mt-n12">
          <!--  v-rellax="{speed: -1.5}" -->
          <div class="d-flex justify-center mb-16">
            <v-img
              src="@/assets/images/bullhorn.svg"
              contain
              height="95"
            ></v-img>
          </div>
          <div
            class="transition-swing text-h3 text-center"
            v-html="$t('pages.home.future.title')"
          ></div>
          <div
            class="transition-swing text-h6 text-center text--secondary mt-12"
            v-html="$t('pages.home.future.subtitle')"
          ></div>
        </div>
      </div>
      <v-row>
        <v-col v-for="(box, i) in $t('pages.home.future.boxes')" :key="i">
          <v-img
            height="70"
            :src="req(box.image)"
            contain
          ></v-img> 
          <div class="text-h5 text-center mt-6 mb-3">{{ box.title }}</div>
          <div class="text-p text-center text--secondary">
           {{ box.text }}
          </div></v-col
        >
       
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Future",

  data() {
    return {
      
    };
  },
  methods: {
    req(img){
      return require(`@/assets/images/${img}`)
    }
  }
};
</script>
