<template>
  <div class="logo-group">
    <div class="--carousel">
      <div class="--line first-line d-flex justify-space-around">
        <template v-for="(logo, index) in lines.first">
          <v-img v-if="lines.first.length == 30"
            :key="index"
            :src="logo"
            contain
            width="85"
            max-width="150"
            class="mx-3 my-3"
            height="60"
            max-height="60"
          ></v-img>
          <v-img v-if="lines.first.length  < 30"
            :key="index"
            :src="logo"
            contain
            width="250"
            max-width="250"
            class="mx-4 my-4"
            height="50"
            max-height="50"
          ></v-img>
        </template>
      </div>
     
    </div>
  </div>
</template>
<script>
export default {
  name: "LogoGroup",
  props: {
    logos: Array,
    title: String,
  },
  computed: {
    lines() {
      const firstHalf = this.logos;
      return {
        first: firstHalf,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.--carousel {
  height: 300px;
}
.--line {
  flex-wrap: wrap;
}
</style>