<template>
  <div class="hero">
    <v-app dark>
      <AppHeader
       v-if="!$route.path.includes('/investors/')"
        :position="'fixed'"
        :hide-on-scroll="true"
        :dark="scrollPosition > 10"
        @toggle="drawer = $event"
      ></AppHeader>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        class="hidden-md-and-up"
      >
        <v-menu  class="pa-6">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" block class="mb-3 mt-12">
              {{ $t("nav.solutions") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item href="https://canaldoanuncio.com" target="_blank">
              <v-list-item-content>
                <v-list-item-title
                  >Canal do Anúncio
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://canaldomidia.com" target="_blank">
              <v-list-item-content>
                <v-list-item-title
                  >Canal do Mídia
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              href="https://franquias.canaldoanuncio.com"
              target="_blank"
            >
              <v-list-item-content>
                <v-list-item-title
                  >Franquias
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://eugencias.com/" target="_blank">
              <v-list-item-content>
                <v-list-item-title
                  >In Houses
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              href="https://app.canaldoanuncio.com/portal-do-veiculo"
              target="_blank"
            >
              <v-list-item-content>
                <v-list-item-title
                  >Veículos de Comunicação
                  <v-icon small right
                    >mdi-arrow-top-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn to="/media-insiders" text block class="my-3">
          {{ $t("nav.media-insiders") }}
        </v-btn>
        <v-btn to="/careers" text block class="my-3">
          {{ $t("nav.careers") }}
        </v-btn>
        <v-btn to="/investors" text block class="my-3">
          {{ $t("nav.investors") }}
        </v-btn>
        <v-btn to="/contact-us" text block class="my-3">
          {{ $t("nav.contact-us") }}
        </v-btn>
        <v-btn
          href="https://store.themediatrade.com"
          color="white"
          light
          target="_blank"
          class="mx-6 my-3"
        >
          <v-icon left class="ml-n1">mdi-shopping</v-icon>
          {{ $t("nav.store") }}
          <v-icon small right class="ml-3">mdi-arrow-top-right</v-icon>
        </v-btn>
      </v-navigation-drawer>

      <!-- <AppHeader v-if="showScrolledMenu" :position="'fixed'" :hide-on-scroll="false"></AppHeader> -->
      <v-main>
        <router-view />
      </v-main>
      <AppFooter v-if="!$route.path.includes('/investors/')"></AppFooter>
    </v-app>
  </div>
</template>

<script>
import AppHeader from "@/interfaces/AppHeader.vue";
import AppFooter from '@/interfaces/AppFooter.vue';
export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
      drawer: false,
      v1: false,
      model: [],
      scrollPosition: 0,
      showScrolledMenu: false,
      loading: false,
    };

    //
  },

  created() {
    this.$vuetify.theme.dark = true;
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  watch: {
    scrollPosition(nV, oV) {
      if (nV > 100) {
        if (nV < oV) {
          this.showScrolledMenu = true;
        } else {
          this.showScrolledMenu = false;
        }
      } else {
        this.showScrolledMenu = false;
      }
    },
    
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>
<style lang="scss">
*,
.v-application * {
  font-family: "Poppins", sans-serif !important;
  .text-h1,
  .text-h2,
  .text-h3,
  .text-h4,
  .text-h5,
  .text-h6,
  .text-subtitle-1,
  .text-subtitle-2,
  .text-body-1,
  .text-body-2,
  .text-button,
  .text-caption,
  .text-overline {
    font-family: "Poppins", sans-serif !important;
  }
  @media screen and (max-width: 960px) {
    .text-h1 {
      font-size: 3em !important;
      line-height: 1.15 !important;
    }
  }
}

.v-application .v-application--wrap a:not(.v-btn) {
  text-decoration: none;
  color: #ffffff;
}
.v-btn {
  letter-spacing: normal !important;
  text-transform: none !important;
  &:not(.v-btn--text) {
    font-weight: bold;
  }
}
.hero {
  // background: linear-gradient(
  //   135deg,
  //   rgba(35, 58, 117, 1) 0%,
  //   rgba(35, 58, 117, 1) 10%,
  //   rgba(1, 11, 36, 1) 70%,
  //   rgb(0, 0, 0, 1) 100%
  // );
  background: #000;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  position: relative;
  margin: 0;
}
.logo {
  min-width: 63.5px;
  background: #233a75;
  width: 70px;
  height: 75px !important;
  border: none;
  padding: 0;
  min-width: 63.5px !important;
  &:before {
    background: none;
  }
  &.v-btn--active::before {
    opacity: 0 !important;
  }
}
.theme--light.v-application,
.theme--dark.v-application {
  background: none !important;
}
.v-toolbar__content {
  // height: 74px !important;
}

.v-footer {
  background: #233a75 !important;
}
.vh-100 {
  min-height: 100vh;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 8px;
}
</style>