<template>
  <div
  >
    <v-container
      v-for="(item, index) in this.$t('pages.home.features.blocks')"
      :key="index"
      :class="{
        evidence:
          bounces.length &&
          scrollPosition > bounces[index].top - 100 &&
          bounces.length &&
          scrollPosition < bounces[index].top + 200,
      }"
    >
      <div
        class="d-flex"
      >
        <div
          :class="{
            showing:
              bounces.length &&
              scrollPosition > bounces[index].top - 100 &&
              bounces.length &&
              scrollPosition < bounces[index].top + 200,
            topped:
              bounces.length && scrollPosition >= bounces[index].top + 200,
          }"
          
        >
          <div
            class="text-h1 d-flex flex-column font-weight-regular"
           
          >
            <div>
              {{ $t("pages.home.features.we_are") }}
            </div>
            <span
              class="border-text font-weight-bold"
              v-html="item.title"
            ></span>
          </div>
          <v-container class="px-0">
            <div
              class="text-h4 my-6 font-weight-bold"
              v-html="item.subtitle"
            ></div>
            <div class="text-p my-6" v-html="item.text"></div>
            <!-- <router-link :to="item.url" class="mt-12">
                <v-icon>mdi-arrow-right-circle</v-icon> Saiba mais</router-link
              > -->
          </v-container>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Features",

  data() {
    return {
      bounces: [],
      split: -1,
      current: 0,
      next: 1,
    };
  },
  props: {
    scrollPosition: Number,
    isActive: Boolean,
  },
  mounted() {
    const headings = document.querySelectorAll(".feature a[name]");
    headings.forEach((ha) => {
      // console.log('ha', ha)
      const rect = ha.getBoundingClientRect();
      this.bounces.push({ id: ha.name, top: rect.top });
    });
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.features {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(35, 58, 117, 1) 50%,
    rgba(35, 58, 117, 1) 100%
  );
  padding-bottom: 300px;
}

.border-text {
  -webkit-text-stroke: 1px #fff !important;
  text-stroke: 2px #fff !important;
  -webkit-text-fill-color: transparent !important;
  text-fill-color: transparent !important;
  color: transparent !important;
}

.feature {
  position: relative;
  z-index: -1;
  &.evidence {
    z-index: 1;
  }
}

.feature-anchor {
  position: absolute;
  top: 0;
  left: 0;
}

.--block-effect {
  z-index: -1;
  opacity: 0;
  margin-top: 50px;
  transition: 0.5s all;
  &.showing {
    z-index: 0;
    opacity: 1;
    margin-top: 0;
  }
  &.topped {
    margin-top: -50px;
    z-index: 0;
  }
}

.--features-base {
  width: 100vw;
  height: 100vh;
  grid-row: 1;
  grid-column: 1;
  top: 0;
  position: sticky;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(35, 58, 117, 1) 100%
  );
}

.nozindexed {
  z-index: -1;
}

.zindexed {
  z-index: 1;
}
</style>