var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"features",staticStyle:{"display":"grid","grid-template-columns":"1fr","grid-template-rows":"1fr"}},[_c('div',{staticClass:"--features-base",staticStyle:{"min-width":"100vw"}}),_c('div',{staticStyle:{"grid-row":"1","grid-column":"1","padding-top":"150px"}},[_c('div',{staticClass:"nozindexed",class:{
        zindexed:
          _vm.bounces.length &&
          _vm.scrollPosition > _vm.bounces[0].top - 100 &&
          _vm.bounces.length &&
          _vm.scrollPosition < _vm.bounces[_vm.bounces.length - 1].top + 200,
      },staticStyle:{"position":"fixed","width":"100vw","top":"0","left":"0","height":"100vh"}},[_c('v-container',{staticClass:"vh-100 d-flex"},[(_vm.bounces.length)?_c('div',{staticClass:"vh-100 d-flex nozindexed",class:{
            zindexed:
              _vm.scrollPosition > _vm.bounces[0].top - 100 &&
              _vm.scrollPosition < _vm.bounces[_vm.bounces.length - 1].top + 200,
          },style:('position: fixed; top: 0; padding-top: 100px')},[_c('div',{staticClass:"--block-effect",class:{
              showing:
                _vm.bounces.length &&
                _vm.scrollPosition > _vm.bounces[0].top - 100 &&
                _vm.bounces.length &&
                _vm.scrollPosition < _vm.bounces[_vm.bounces.length - 1].top + 200,
              topped:
                _vm.bounces.length &&
                _vm.scrollPosition >= _vm.bounces[_vm.bounces.length - 1].top + 200,
            }},[_c('div',{staticClass:"\n                text-h1\n                d-flex\n                mb-12\n                mt-n1\n                pb-12\n                font-weight-regular\n                text-no-wrap\n              "},[_vm._v(" "+_vm._s(_vm.$t("pages.home.features.we_are"))+" ")])])]):_vm._e()])],1),_vm._l((this.$t('pages.home.features.blocks')),function(item,index){return _c('v-container',{key:index,staticClass:"vh-100 d-flex feature",class:{
        evidence:
          _vm.bounces.length &&
          _vm.scrollPosition > _vm.bounces[index].top - 100 &&
          _vm.bounces.length &&
          _vm.scrollPosition < _vm.bounces[index].top + 200,
      }},[_c('a',{staticClass:"feature-anchor",attrs:{"name":item.title}}),_c('div',{staticClass:"vh-100 d-flex",style:('position: fixed; top: 0; padding-top: 100px')},[_c('div',{staticClass:"--block-effect",class:{
            showing:
              _vm.bounces.length &&
              _vm.scrollPosition > _vm.bounces[index].top - 100 &&
              _vm.bounces.length &&
              _vm.scrollPosition < _vm.bounces[index].top + 200,
            topped:
              _vm.bounces.length && _vm.scrollPosition >= _vm.bounces[index].top + 200,
          }},[_c('div',{staticClass:"\n              text-h1\n              d-flex\n              mb-12\n              pb-12\n              font-weight-regular\n              text-no-wrap\n            ",staticStyle:{"position":"absolute"}},[_c('div',{staticStyle:{"opacity":"0"}},[_vm._v(" "+_vm._s(_vm.$t("pages.home.features.we_are"))+" ")]),_c('span',{staticClass:"border-text font-weight-bold text-no-wrap ml-3",domProps:{"innerHTML":_vm._s(item.title)}})]),_c('v-container',{staticClass:"px-0 pt-16 mt-12"},[_c('div',{staticClass:"text-h4 my-12 font-weight-bold",domProps:{"innerHTML":_vm._s(item.subtitle)}}),_c('div',{staticClass:"text-p my-6",domProps:{"innerHTML":_vm._s(item.text)}})])],1)])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }