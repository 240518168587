<template>
  <v-footer>
    <div class="block" style="width: 100%">
      <v-container>
        <div class="d-flex justify-space-between flex-column flex-md-row">
          <div style="min-width: 20%">
            <v-img
              to="/"
              alt="Vuetify Logo"
              contain
              src="@/assets/images/logo.svg"
              max-width="100"
              aspect-ratio="1"
            ></v-img>

            <div
              class="text-caption text--secondary mt-3"
              style="max-width: 200px"
            >
              © 2015-2022 The Media Trade<br />23.659.903/0001-84<br />
              {{ $t("footer.copy_rights") }}
            </div>
            <div class="d-flex pt-9">
              <a
                href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fthemediatrade.com"
                target="_blank"
                ><v-img
                  src="@/assets/images/seal-google-safe-browsing.svg"
                  height="40"
                  width="100"
                  contain
                ></v-img
              ></a>
              <v-img
                src="@/assets/images/seal-alpha-ssl.svg"
                height="40"
                width="40"
                contain
              ></v-img>
            </div>
          </div>
          <div class="py-6">
            <v-list flat dense style="background: none !important" v-if="!v1">
              <v-subheader class="text-h6 text--primary ml-2">{{
                $t("footer.company.title")
              }}</v-subheader>
              <v-list-item-group v-model="model">
                <v-list-item to="/about">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("footer.company.about")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/careers">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("footer.company.careers")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/insiders">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("footer.company.media_insiders")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("footer.company.investors")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/contact-us">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("footer.company.contact_us")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="py-6">
            <v-list flat dense style="background: none !important" v-if="!v1">
              <v-subheader class="text-h6 text--primary ml-2">{{
                $t("footer.solutions_for.title")
              }}</v-subheader>
              <v-list-item-group v-model="model">
                <v-list-item target="_blank" href="https://canaldoanuncio.com">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("footer.solutions_for.advertisers") }}
                      <v-icon small right
                        >mdi-arrow-top-right</v-icon
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item target="_blank" href="https://canaldomidia.com">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("footer.solutions_for.agencies") }}
                      <v-icon small right
                        >mdi-arrow-top-right</v-icon
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  target="_blank" href="https://franquias.canaldoanuncio.com"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("footer.solutions_for.franchise") }}
                      <v-icon small right
                        >mdi-arrow-top-right</v-icon
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item target="_blank" href="https://eugencias.com/">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("footer.solutions_for.in_houses") }}
                      <v-icon small right
                        >mdi-arrow-top-right</v-icon
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  target="_blank" href="https://app.canaldoanuncio.com/portal-do-veiculo"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("footer.solutions_for.publishers") }}
                      <v-icon small right
                        >mdi-arrow-top-right</v-icon
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="py-6">
            <v-list flat dense style="background: none !important" v-if="!v1">
              <v-subheader class="text-h6 text--primary ml-2">{{
                $t("footer.follow_us.title")
              }}</v-subheader>
              <v-list-item-group v-model="model">
                <v-list-item href="https://www.linkedin.com/company/media-trade" target="_blank">
                  <v-list-item-icon>
                    <v-icon>mdi-linkedin</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content small class="social-media">
                    <v-list-item-title
                      >LinkedIn
                      <v-icon small class="ml-6"
                        >mdi-arrow-top-right</v-icon
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-facebook</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Facebook
                          <v-icon small right
                            >mdi-arrow-top-right</v-icon
                          ></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item> -->
                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                      x-large
                      color="white"
                      class="mt-12 px-9"
                      light
                      href="https://store.themediatrade.com"
                      target="_blank"
                      ><v-icon left class="ml-n6">mdi-shopping</v-icon>The Media
                      Store

                      <v-icon small right class="mr-n6"
                        >mdi-arrow-top-right</v-icon
                      ></v-btn
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </v-container>
      <v-divider class="my-6"></v-divider>
      <v-container>
        <div class="d-flex mb-3" style="font-size: 0.75em">
          <router-link to="/policitas-de-cookie-e-privacidade">{{
            $t("footer.cookies_policy_privacity")
          }}</router-link>
          <v-divider vertical class="mx-6"></v-divider>
          <router-link to="/policitas-de-cookie-e-privacidade">{{
            $t("footer.terms_conditions")
          }}</router-link>
          <v-spacer></v-spacer>
        </div>
      </v-container>
    </div>
  </v-footer>
</template>
<script>

export default {
    name: "Footer"
}
</script>
