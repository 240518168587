import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import { getFirestore, doc, collection, getDoc, getDocs, setDoc, where, query } from 'firebase/firestore';
import VueTheMask from 'vue-the-mask'
import VueRellax from 'vue-rellax'
import { getAuth, onAuthStateChanged } from "firebase/auth";

// import * as VueThreejs from 'vue-threejs' // >= 0.2.0


Vue.use(VueRellax)
Vue.use(VueTheMask)
// Vue.use(VueThreejs)

// import Vuelidate from 'vuelidate'
// import { firestorePlugin } from 'vuefire'

import { initializeApp } from 'firebase/app';

// Vue.use(Vuelidate)
const firebaseConfig = {
  apiKey: "AIzaSyC2MxKmc9tvCjR-L7Wg9LuzJucH39wddds",
  authDomain: "tmt--i.firebaseapp.com",
  projectId: "tmt--i",
  storageBucket: "tmt--i.appspot.com",
  messagingSenderId: "423941767135",
  appId: "1:423941767135:web:d7cc147b0c7b415773ce6a",
  measurementId: "G-6GRW5SYH1H"
};
const FireStore = initializeApp(firebaseConfig);


const db = getFirestore(FireStore);
Vue.prototype.$db = db;
Vue.prototype.$collection = collection;
Vue.prototype.$doc = doc;
Vue.prototype.$getDoc = getDoc;
Vue.prototype.$getDocs = getDocs;
Vue.prototype.$setDoc = setDoc;
Vue.prototype.$where = where;
Vue.prototype.$query = query;

// Vue.use(firestorePlugin)

Vue.config.productionTip = false

let app = '';

const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    Vue.prototype.$user = user;
  }
  else {
    Vue.prototype.$user = null
  }
  if (!app)
    app =
      new Vue({
        router,
        vuetify,
        i18n,
        render: h => h(App)
      }).$mount('#app')
})
