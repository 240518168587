<template>
  <div
    class="d-flex flex-column"
    style="min-height: 100vh; width: 100vw; background: #131313"
  >
    <div>
      <router-link to="/"
        ><v-img
          src="@/assets/images/logo.svg"
          class="mx-auto my-6"
          contain
          height="75"
        ></v-img
      ></router-link>
    </div>
    <v-card width="480px" class="mx-auto px-6 py-3" elevation="6" outlined>
      <v-card-title class="justify-center py-6"
        >Register your account</v-card-title
      >
      <v-card-text>
        <div class="py-1"><label>Name</label></div>
        <v-text-field
          v-model="name"
          outlined
          placeholder="Name"
          dense
        ></v-text-field>
        <div class="py-1"><label>E-mail</label></div>
        <v-text-field
          v-model="email"
          outlined
          placeholder="E-mail"
          dense
        ></v-text-field>
        <div class="py-1"><label>Password</label></div>

        <v-text-field
          v-model="password"
          outlined
          placeholder="Password"
          dense
        ></v-text-field>
        <div class="py-1"><label>Repeat your password</label></div>

        <v-text-field
          v-model="passwordRepeat"
          outlined
          placeholder="Password confirm"
          dense
        ></v-text-field>
        <v-btn color="accent" x-large block @click="submit()">Login now</v-btn>
        <v-divider></v-divider>
        <v-btn color="white" x-large light block @click="googleAuth()"
          >Google</v-btn
        >

        <div class="pb-9 pt-6">
          Already registered?
          <router-link text to="/investors/login" class="pa-0"
            >Login
            <v-icon small class="ml-3">mdi-arrow-right</v-icon></router-link
          >
        </div>
      </v-card-text>
    </v-card>
    <div class="pa-3"></div>
  </div>
</template>
<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from "firebase/auth";

export default {
  name: "Signup",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      passwordRepeat: "",
      user: null,
    };
  },
  methods: {
    submit() {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          this.user = user;
          updateProfile(auth.currentUser, {
            displayName: this.name,
          })
            .then(() => {
              this.$router.replace("/investors/app");
            })
            .catch((error) => {
              console.error("error on update user", error);
            });

          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("Error code", errorCode);
          console.log("Error message", errorMessage);
          // ..
        });
    },
    googleAuth() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          console.log(token);
          // The signed-in user info.
          const user = result.user;
          this.user = user;
          this.$router.replace("/investors/app");

          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          console.error("errorMessage", errorMessage);

          console.error("errorCode", errorCode);

          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.error("credential", credential);
          // ...
        });
    },
  },
};
</script>