<template>
  <div class="about my-16">
    <v-container
      v-if="
        !loading &&
        documents.length &&
        documents[0].status == 1 &&
        $route.params.slug
      "
    >
      <v-row>
        <v-col cols="12" md="12">
          <v-img
            width="40%"
            style="float: left"
            class="mb-12 mr-12 hidden-sm-and-down"
            :src="documents[0].coverage"
          ></v-img>
          <v-img
            width="100%"
            class="mb-12 mr-12 hidden-md-and-up"
            :src="documents[0].coverage"
          ></v-img>
          <div class="d-flex flex-wrap">
            <v-chip
            label
            color="#212121"
              v-for="(tag, index) in tags"
              :key="index"
              :to="`/media-insiders/tag/${tag}`"
              class="mr-3 font-weight-bold text-uppercase"
              ><v-icon x-small class="mr-1">mdi-pound</v-icon>{{ tag }}</v-chip
            >
          </div>
          <div class="text-h3 my-12" v-html="documents[0].title"></div>
          <div class="text-p" v-html="documents[0].subtitle"></div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!loading && documents.length && $route.params.tag">
      <div class="text-h2 my-12"><v-icon large class="mr-3">mdi-pound</v-icon>{{ $route.params.tag }}</div>

      <v-row>
        <v-col cols="12" md="4" v-for="doc in documents" :key="doc.id">
          <post :document="doc"></post>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="
        !loading &&
        documents.length &&
        !$route.params.tag &&
        !$route.params.slug
      "
    >
      <div class="d-flex flex-wrap pb-12">
        <v-chip
        color="#212121"
            label
          v-for="(tag, index) in tags"
          :key="index"
          :to="`/media-insiders/tag/${tag}`"
          class="mr-3 font-weight-bold text-uppercase"
          ><v-icon x-small class="mr-1">mdi-pound</v-icon>{{ tag }}</v-chip
        >
      </div>

      <v-row>
        <v-col cols="12" md="4" v-for="doc in documents" :key="doc.id">
          <post :document="doc"></post>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="loading">
      <!-- <div class="d-flex justify-center"><v-img src="@/assets/images/loading.svg" max-width="48" max-height="48"></v-img></div> -->
          <v-skeleton-loader class="my-6" type="heading"></v-skeleton-loader>
      <v-row>
        <v-col
          ><v-skeleton-loader class="mt-3" type="card"></v-skeleton-loader
        ></v-col>

        <v-col
          ><v-skeleton-loader class="mt-3" type="card"></v-skeleton-loader
        ></v-col>
        <v-col
          ><v-skeleton-loader class="mt-3" type="card"></v-skeleton-loader
        ></v-col>
      </v-row>
    </v-container>
    <div
      class="my-6"
      v-if="
        !loading &&
        documents.length &&
        ($route.params.tag || $route.params.slug)
      "
    >
      <v-container>
        <v-btn color="white" light x-large to="/media-insiders"
          >Ver outros posts
          <v-icon small right class="ml-9">mdi-arrow-right</v-icon></v-btn
        >
      </v-container>
    </div>
  </div>
</template>

<script>
import Post from "@/components/Insiders/Post.vue";
import { orderBy } from "firebase/firestore";

export default {
  name: "MediaInsiderPost",
  components: {
    Post,
  },
  data() {
    return {
      documents: [],
      loading: true,
      tags: [],
    };
  },
  mounted() {
    this.loading = true;
    this.load();
  },
  watch: {
    "$route.path"(nV) {
      console.log(nV);
      this.load();
    },
  },
  methods: {
    load() {
      this.tags = [];
      this.documents = [];
      this.loading = true;
      let q = null;


      if (this.$route.params.slug) {
        q = this.$query(
          this.$collection(this.$db, "News"),
          this.$where("slug", "==", this.$route.params.slug)
        );
      } else if (this.$route.params.tag) {
        q = this.$query(
          this.$collection(this.$db, "News"),
          this.$where("tags", "array-contains", this.$route.params.tag),
          orderBy("createdOn", "desc")
        );
      } else {
        q = this.$query(
          this.$collection(this.$db, "News"),
          this.$where("status", "==", 1),
          orderBy("createdOn", "desc")
        );
      }

      const querySnapshot = this.$getDocs(q);
      querySnapshot.then((snap) => {
        // console.log("snap", snap);
        const documents = snap.docs.map((doc) => doc.data());
        let tags = [];
        documents.forEach((doc) => {
          doc.tags.forEach((tag) => {
            if (!tags.includes(tag)) {
              tags.push(tag);
            }
          });
        });
        this.tags = tags;
        this.documents = documents;
        this.loading = false;
      });
    },
  },
};
</script>
<style>
 .text-p *, .text-p p, .text-p p span{
   color: #ffffff !important;
 }
</style>