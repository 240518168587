<template>
  <form class="-contact-form">
    <template v-if="!loadingLang">
      <v-row>
        <v-col>
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :counter="40"
            :label="$t('contact_form.fields.name.label')"
            color="white"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            color="white"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            persistent-placeholder
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="phone"
            :error-messages="phoneErrors"
            :label="$t('contact_form.fields.phone.label')"
            color="white"
            required
            v-mask="phoneMask"
            @input="$v.phone.$touch()"
            @blur="$v.phone.$touch()"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            :error-messages="subjectFormErrors"
            required
            v-model="subjectForm"
            :readonly="lock"
            :items="items"
            :label="$t('contact_form.fields.subject.label')"
            color="white"
            dark
            class="--subject"
            persistent-placeholder
            @change="$v.subjectForm.$touch()"
            @blur="$v.subjectForm.$touch()"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-12">
          <v-textarea
            color="white"
            counter="200"
            v-model="message"
            required
            :error-messages="messageErrors"
            @input="$v.message.$touch()"
            @blur="$v.message.$touch()"
            :label="$t('contact_form.fields.message.label')"
            persistent-placeholder
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row class="justify-end">
        <v-btn
          class="mr-4 mb-16"
          right
          color="accent"
          @click="submit"
          :loading="loading"
          :disabled="$v.$invalid"
          x-large
          >{{ $t("contact_form.send") }}
          <v-icon small class="ml-12">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>
      <v-dialog v-model="success" max-width="480">
        <v-card color="accent" class="py-9 px-7">
          <v-card-subtitle>
            <v-img
              v-if="success"
              src="@/assets/images/confirm.svg"
              contain
              class="relative"
              height="140"
            ></v-img>
            <div
              class="text-h5 my-6 text-center relative evidence"
              v-html="$t('contact_form.success_message.title')"
            ></div>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn outlined @click="done()" x-large class="mx-auto">{{
              $t("contact_form.success_message.confirm")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </form>
</template>

<script>
import moment from "moment";

import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "ContactFrom",
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(40) },
    message: { required, minLength: minLength(10), maxLength: maxLength(200) },
    phone: { required, minLength: minLength(14), maxLength: maxLength(16) },
    email: { required, email },
    subjectForm: { required },
  },

  data: () => ({
    loadingLang: false,
    preloading: true,
    phoneMask: "",
    lock: false,
    name: "",
    email: "",
    loading: false,
    phone: "",
    subjectForm: null,
    message: "",
    success: false,
    items: [],
  }),
  props: {
    subjectIndex: {
      required: false,
      type: Number,
    },
  },
  watch: {
    success(value) {
      console.log("value", value);
      if (value == false) {
        this.clear();
      }
    },
    '$i18n.locale'(){
      this.loadingLang = true
      setTimeout(() => {
        this.loadingLang = false
      },100)
    }
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push(this.$t("contact_form.fields.name.errors.max_length"));
      !this.$v.name.required &&
        errors.push(this.$t("contact_form.fields.name.errors.required"));
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push(this.$t("contact_form.fields.email.errors.email"));
      !this.$v.email.required &&
        errors.push(this.$t("contact_form.fields.email.errors.required"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.maxLength &&
        errors.push(this.$t("contact_form.fields.phone.errors.min_length"));
      !this.$v.phone.minLength &&
        errors.push(this.$t("contact_form.fields.phone.errors.max_length"));
      !this.$v.phone.required &&
        errors.push(this.$t("contact_form.fields.phone.errors.required"));
      return errors;
    },
    subjectFormErrors() {
      const errors = [];
      if (!this.$v.subjectForm.$dirty) return errors;
      !this.$v.subjectForm.required &&
        errors.push(this.$t("contact_form.fields.subject.errors.required"));

      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required &&
        errors.push(this.$t("contact_form.fields.message.errors.required"));
      !this.$v.message.minLength &&
        errors.push(this.$t("contact_form.fields.message.errors.min_length"));
      !this.$v.message.maxLength &&
        errors.push(this.$t("contact_form.fields.message.errors.max_length"));

      return errors;
    },
  },

  created() {
    this.items = this.$t("contact_form.fields.subject.list");
    setTimeout(() => {
      if (this.subjectIndex || this.subjectIndex > -1) {
        this.subjectForm = this.items[this.subjectIndex];
        this.lock = true;
      }
    }, 500);
  },

  methods: {
    submit() {
      this.loading = true;
      this.$v.$touch();
      setTimeout(() => {
        if (this.$v.$invalid) {
          this.loading = false;
          console.log(this.$v.$invalid);
        } else {
          const insertDoc = this.$setDoc(
            this.$doc(this.$db, "ContactMessages", this.email),
            {
              name: this.name,
              email: this.email,
              phone: this.phone,
              subject: this.subjectForm,
              message: this.message,
              createdOn: moment().format(),
            }
          );
          insertDoc.then((doc) => {
            console.log("DOC", doc);
            this.loading = false;
            this.success = true;
          });
        }
      }, 1000);
    },
    done() {
      this.success = false;
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.subjectForm = null;
      this.phone = "";
      this.message = "";
    },
  },
};
</script>

<style lang="scss">
.-contact-form {
  > * {
    margin-bottom: -22px;
  }
  ::placeholder {
    color: #212121;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 0 0;
  }
  .v-text-field .v-label {
    margin-top: -6px;
  }
  .v-input input,
  .-contact-form.v-input textarea,
  .v-textarea textarea {
    caret-color: black;
    color: #000;
    background: #fff;
    border-radius: 3px;
    padding: 20px 12px;
  }
  .v-text-field > .v-input__control > .v-input__slot:after,
  .v-text-field > .v-input__control > .v-input__slot:before {
    opacity: 0;
  }
  .theme--dark.v-select .v-select__selections {
    color: #000;
    background: #fff;
    border-radius: 3px;
    padding: 1px 12px;
  }
}

.--subject {
  .v-select__slot {
    background: #fff;
    border-radius: 3px;
  }
  .v-input__icon {
    color: #212121;
    margin-top: 7px;
    * {
      color: #212121 !important;
    }
  }
}

.relative {
  position: relative;
}

.evidence {
  z-index: 1;
}
</style>