<template>
  <div>
    <v-container class="pb-16 mb-16">
      <v-img
        src="@/assets/images/cube.svg"
        height="50"
        width="50"
        contain
        class="mb-12"
      ></v-img>

      <div
        class="text-h1 pb-12"
        v-html="$t('pages.home.companies.title')"
      ></div>
      <div class="d-flex justify-end">
        <v-img
          src="@/assets/images/cube.svg"
          height="50"
          max-width="50"
          contain
          class="mb-12"
        ></v-img>
      </div>

      <!-- <div class="text-caption">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione
        officia rerum doloremque, qui explicabo culpa ex quis? Maiores quaerat
        facilis et in reiciendis esse, magnam saepe voluptatum quae hic soluta?
      </div> -->
      <v-tabs
        v-model="current"
        background-color="black"
        centered
        class="pb-12 --custom-tabs"
      >
        <v-tabs-slider color="black"></v-tabs-slider>
        <v-tab v-for="(group, index) in groups" :key="index">
          <v-btn :color="currentColor[index]">{{ group.title }}</v-btn>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="current" style="background-color: #000">
        <v-tab-item v-for="(group, index) in groups" :key="index">
          <LogoGroup :title="group.title" :logos="group.logos"></LogoGroup>
          <div class="text-h4 text-center" v-html="group.text"></div>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>
<script>
import LogoGroup from "./Companies/LogoGroup.vue";
export default {
  components: {
    LogoGroup,
  },
  computed: {
    currentColor() {
      let indexes = [];
      this.groups.forEach((item, index) => {
        if (index == this.current) indexes.push("accent");
        else indexes.push("");
      });
      return indexes;
    },
  },
  data() {
    return {
      current: 0,
      groups: [],
      logos: [
        [
          require("@/assets/images/companies/advertisers/10pasteis.svg"),
          require("@/assets/images/companies/advertisers/amazon_transportes.svg"),
          require("@/assets/images/companies/advertisers/bio_extratus.svg"),
          require("@/assets/images/companies/advertisers/bledpet.svg"),
          require("@/assets/images/companies/advertisers/bolsaimovel.svg"),
          require("@/assets/images/companies/advertisers/butantan.svg"),
          require("@/assets/images/companies/advertisers/cbb.svg"),
          require("@/assets/images/companies/advertisers/cia_de_talentos.svg"),
          require("@/assets/images/companies/advertisers/dasa.svg"),
          require("@/assets/images/companies/advertisers/dog_hero.svg"),
          require("@/assets/images/companies/advertisers/eatsforyou.svg"),
          require("@/assets/images/companies/advertisers/ens.svg"),
          require("@/assets/images/companies/advertisers/faro_energy.svg"),
          require("@/assets/images/companies/advertisers/goya.svg"),
          require("@/assets/images/companies/advertisers/gupy.svg"),
          require("@/assets/images/companies/advertisers/iga.svg"),
          require("@/assets/images/companies/advertisers/jamef.svg"),
          require("@/assets/images/companies/advertisers/lpcosmeticos.svg"),
          require("@/assets/images/companies/advertisers/mitre.svg"),
          require("@/assets/images/companies/advertisers/mondial.svg"),
          require("@/assets/images/companies/advertisers/mrv.svg"),
          require("@/assets/images/companies/advertisers/orbia.svg"),
          require("@/assets/images/companies/advertisers/oticas_wanny.svg"),
          require("@/assets/images/companies/advertisers/pela_vidda.svg"),
          require("@/assets/images/companies/advertisers/randstad.svg"),
          require("@/assets/images/companies/advertisers/thyssenkrupp.svg"),
          require("@/assets/images/companies/advertisers/unifesp.svg"),
          require("@/assets/images/companies/advertisers/villarossa.svg"),
          require("@/assets/images/companies/advertisers/world_animal_protection.svg"),
          require("@/assets/images/companies/advertisers/z_tech.svg"),
        ],
        [
          require("@/assets/images/companies/agencies/casa_de_bolos.svg"),
          require("@/assets/images/companies/agencies/inovatorio.svg"),
          require("@/assets/images/companies/agencies/keep1.svg"),
          require("@/assets/images/companies/agencies/leme_digital.svg"),
          require("@/assets/images/companies/agencies/newton.svg"),
          require("@/assets/images/companies/agencies/oni.svg"),
          require("@/assets/images/companies/agencies/propague.svg"),
          require("@/assets/images/companies/agencies/sallero.svg"),
          require("@/assets/images/companies/agencies/tiee.svg"),
          require("@/assets/images/companies/agencies/todos_os_santos.svg"),
          require("@/assets/images/companies/agencies/trammit.svg"),
          require("@/assets/images/companies/agencies/tripe.svg"),
          require("@/assets/images/companies/agencies/wedigi.svg"),
        ],
        [
          require("@/assets/images/companies/publishers/azerion.svg"),
          require("@/assets/images/companies/publishers/band.svg"),
          require("@/assets/images/companies/publishers/cbn.svg"),
          require("@/assets/images/companies/publishers/clear-channel.svg"),
          require("@/assets/images/companies/publishers/cnn.svg"),
          require("@/assets/images/companies/publishers/disney.svg"),
          require("@/assets/images/companies/publishers/editora-caras.svg"),
          require("@/assets/images/companies/publishers/editora-tres.svg"),
          require("@/assets/images/companies/publishers/eletromidia.svg"),
          require("@/assets/images/companies/publishers/etermax.svg"),
          require("@/assets/images/companies/publishers/flixmedia.svg"),
          require("@/assets/images/companies/publishers/grupo-abril.svg"),
          require("@/assets/images/companies/publishers/grupo-folha.svg"),
          require("@/assets/images/companies/publishers/grupo-globo.svg"),
          require("@/assets/images/companies/publishers/grupo-perfil.svg"),
          require("@/assets/images/companies/publishers/grupo-rbs.svg"),
          require("@/assets/images/companies/publishers/helloo.svg"),
          require("@/assets/images/companies/publishers/jcdecaux.svg"),
          require("@/assets/images/companies/publishers/jovem-pan.svg"),
          require("@/assets/images/companies/publishers/kallas.svg"),
          require("@/assets/images/companies/publishers/metropolitana.svg"),
          require("@/assets/images/companies/publishers/midia-malls.svg"),
          require("@/assets/images/companies/publishers/otima.svg"),
          require("@/assets/images/companies/publishers/paramount.svg"),
          require("@/assets/images/companies/publishers/pdv-company.svg"),
          require("@/assets/images/companies/publishers/radio-mix.svg"),
          require("@/assets/images/companies/publishers/record.svg"),
          require("@/assets/images/companies/publishers/viacom.svg"),
          require("@/assets/images/companies/publishers/warner-media.svg"),
          require("@/assets/images/companies/publishers/webedia.svg"),
        ],
        [
          require("@/assets/images/companies/partners/kantar_ibope_color.svg"),
          require("@/assets/images/companies/partners/logun.svg"),
          require("@/assets/images/companies/partners/espm.svg"),
          require("@/assets/images/companies/partners/tunad.svg"),
        ],
      ],
    };
  },
  mounted() {
    this.groups = this.$t("pages.home.companies.groups");
    this.groups.forEach((group, index) => {
      group.logos = this.logos[index];
    });
  },
};
</script>
<style lang="scss" scoped>
.--custom-tabs {
  @media screen and (max-width: 960px) {
    margin: 0 -12px;
    width: 100vw;
    .v-slide-group__prev{
      display: none !important;
    }
  }
}
</style>