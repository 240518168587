var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((this.$t('pages.home.features.blocks')),function(item,index){return _c('v-container',{key:index,class:{
      evidence:
        _vm.bounces.length &&
        _vm.scrollPosition > _vm.bounces[index].top - 100 &&
        _vm.bounces.length &&
        _vm.scrollPosition < _vm.bounces[index].top + 200,
    }},[_c('div',{staticClass:"d-flex"},[_c('div',{class:{
          showing:
            _vm.bounces.length &&
            _vm.scrollPosition > _vm.bounces[index].top - 100 &&
            _vm.bounces.length &&
            _vm.scrollPosition < _vm.bounces[index].top + 200,
          topped:
            _vm.bounces.length && _vm.scrollPosition >= _vm.bounces[index].top + 200,
        }},[_c('div',{staticClass:"text-h1 d-flex flex-column font-weight-regular"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("pages.home.features.we_are"))+" ")]),_c('span',{staticClass:"border-text font-weight-bold",domProps:{"innerHTML":_vm._s(item.title)}})]),_c('v-container',{staticClass:"px-0"},[_c('div',{staticClass:"text-h4 my-6 font-weight-bold",domProps:{"innerHTML":_vm._s(item.subtitle)}}),_c('div',{staticClass:"text-p my-6",domProps:{"innerHTML":_vm._s(item.text)}})])],1)])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }