<template>
  <div
    class="features"
    style="display: grid; grid-template-columns: 1fr; grid-template-rows: 1fr"
  >
    <div class="--features-base" style="min-width: 100vw"></div>

    <div style="grid-row: 1; grid-column: 1; padding-top: 150px">
      <div
        style="position: fixed; width: 100vw; top: 0; left: 0; height: 100vh"
        class="nozindexed"
        :class="{
          zindexed:
            bounces.length &&
            scrollPosition > bounces[0].top - 100 &&
            bounces.length &&
            scrollPosition < bounces[bounces.length - 1].top + 200,
        }"
      >
        <v-container class="vh-100 d-flex">
          <div
            v-if="bounces.length"
            class="vh-100 d-flex nozindexed"
            :style="'position: fixed; top: 0; padding-top: 100px'"
            :class="{
              zindexed:
                scrollPosition > bounces[0].top - 100 &&
                scrollPosition < bounces[bounces.length - 1].top + 200,
            }"
          >
            <div
              :class="{
                showing:
                  bounces.length &&
                  scrollPosition > bounces[0].top - 100 &&
                  bounces.length &&
                  scrollPosition < bounces[bounces.length - 1].top + 200,
                topped:
                  bounces.length &&
                  scrollPosition >= bounces[bounces.length - 1].top + 200,
              }"
              class="--block-effect"
            >
              <div
                class="
                  text-h1
                  d-flex
                  mb-12
                  mt-n1
                  pb-12
                  font-weight-regular
                  text-no-wrap
                "
              >
                {{ $t("pages.home.features.we_are") }}
              </div>
            </div>
          </div>
        </v-container>
      </div>
      <v-container
        class="vh-100 d-flex feature"
        v-for="(item, index) in this.$t('pages.home.features.blocks')"
        :key="index"
        :class="{
          evidence:
            bounces.length &&
            scrollPosition > bounces[index].top - 100 &&
            bounces.length &&
            scrollPosition < bounces[index].top + 200,
        }"
      >
        <a :name="item.title" class="feature-anchor"></a>
        <div
          class="vh-100 d-flex"
          :style="'position: fixed; top: 0; padding-top: 100px'"
        >
          <div
            :class="{
              showing:
                bounces.length &&
                scrollPosition > bounces[index].top - 100 &&
                bounces.length &&
                scrollPosition < bounces[index].top + 200,
              topped:
                bounces.length && scrollPosition >= bounces[index].top + 200,
            }"
            class="--block-effect"
          >
            <div
              class="
                text-h1
                d-flex
                mb-12
                pb-12
                font-weight-regular
                text-no-wrap
              "
              style="position: absolute"
            >
              <div style="opacity: 0">
                {{ $t("pages.home.features.we_are") }}
              </div>
              <span
                class="border-text font-weight-bold text-no-wrap ml-3"
                v-html="item.title"
              ></span>
            </div>
            <v-container class="px-0 pt-16 mt-12">
              <div
                class="text-h4 my-12 font-weight-bold"
                v-html="item.subtitle"
              ></div>
              <div class="text-p my-6" v-html="item.text"></div>
              <!-- <router-link :to="item.url" class="mt-12">
                <v-icon>mdi-arrow-right-circle</v-icon> Saiba mais</router-link
              > -->
            </v-container>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",

  data() {
    return {
      bounces: [],
      split: -1,
      current: 0,
      next: 1,
    };
  },
  props: {
    scrollPosition: Number,
    isActive: Boolean,
  },
  mounted() {
    const headings = document.querySelectorAll(".feature a[name]");
    headings.forEach((ha) => {
      // console.log('ha', ha)
      const rect = ha.getBoundingClientRect();
      this.bounces.push({ id: ha.name, top: rect.top });
    });
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.text-h1 {
  @media screen and (min-width: 481px){
    font-size: 5.75rem !important;
    line-height: 5.75rem !important;
  }
}

.features {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(35, 58, 117, 1) 50%,
    rgba(35, 58, 117, 1) 100%
  );
  padding-bottom: 300px;
}

.border-text {
  -webkit-text-stroke: 1px #fff !important;
  text-stroke: 2px #fff !important;
  -webkit-text-fill-color: transparent !important;
  text-fill-color: transparent !important;
  color: transparent !important;
}

.feature {
  position: relative;
  z-index: -1;
  transition: .3s all;
  opacity: 0;
  &.evidence {
    z-index: 1;
    opacity: 1;
  }
}

.feature-anchor {
  position: absolute;
  top: 0;
  left: 0;
}

.--block-effect {
  z-index: -1;
  opacity: 0;
  margin-top: 50px;
  transition: 0.5s all;
  &.showing {
    z-index: 0;
    opacity: 1;
    margin-top: 0;
  }
  &.topped {
    margin-top: -50px;
    z-index: 0;
  }
}

.--features-base {
  width: 100vw;
  height: 100vh;
  grid-row: 1;
  grid-column: 1;
  top: 0;
  position: sticky;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(35, 58, 117, 1) 100%
  );
}

.nozindexed {
  z-index: -1;
}

.zindexed {
  z-index: 1;
}
</style>