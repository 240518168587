<template>
  <div class="insiders">
    <!-- <v-container> -->
    <div class="--slider">
      <v-container>
        <v-img
          src="@/assets/images/cube.svg"
          height="50"
          width="50"
          contain
          class="mb-12"
        ></v-img>
        <div class="d-flex justify-space-between mb-6" slot="default">
          <h2 class="text-h2" ref="mediaInsiders">
            <strong>{{ $t("pages.home.media_insiders.title") }}</strong>
          </h2>
          <div class="d-flex">
            <v-btn fab small color="white" light @click="clickLeft"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <v-btn
              fab
              small
              class="ml-6"
              color="white"
              light
              @click="clickRight"
              ><v-icon>mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </div>
      </v-container>
      <v-slide-group v-model="model" :show-arrows="true">
        <v-slide-item v-for="(doc, index) in documents" :key="index">
          <post
            :document="doc"
            :margin="marginPosition"
            :first="index == 0"
            :last="index == documents.length - 1"
          ></post>
        </v-slide-item>
        <template v-slot:prev="{ on, attrs }">
          <v-btn
            fab
            small
            color="white"
            light
            v-bind="attrs"
            v-on="on"
            id="slideBack"
            style="display: none"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn
            fab
            small
            color="white"
            light
            v-bind="attrs"
            id="slideNext"
            style="display: none"
            v-on="on"
            ><v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </template>
        <!-- style="display: none" -->
      </v-slide-group>
    </div>
    <div class="my-6">
      <v-container>
        <v-btn color="white" light x-large to="/media-insiders"
          >{{ $t("pages.home.media_insiders.view_others") }}
          <v-icon small right class="ml-9">mdi-arrow-right</v-icon></v-btn
        >
      </v-container>
    </div>
    <!-- </v-container> -->
  </div>
</template>
<script>
import Post from "@/components/Insiders/Post.vue";
import { orderBy, limit } from "firebase/firestore";

// import { db } from '@/plugins/db'

export default {
  name: "Insiders",
  components: {
    Post,
  },
  data() {
    return {
      model: null,
      previousButton: "",
      nextButton: "",
      isPreviousBtnDisable: true,
      isNextBtnDisable: false,
      documents: [],
      marginPosition: 0,
    };
  },
  //   firestore: {
  //     documents: db.collection('news'),
  //   },
  mounted() {
    this.checkNavBtnStatus();
    this.loadNews();
    let leftPosition = this.$refs.mediaInsiders.getBoundingClientRect().left;
    this.marginPosition = leftPosition;
  },
  methods: {
    loadNews() {
      const q = this.$query(
        this.$collection(this.$db, "News"),
        this.$where("status", "==", 1), orderBy("createdOn", "desc"), limit(6)
      );
      const querySnapshot = this.$getDocs(q);
      querySnapshot.then((snap) => {
        // console.log("snap", snap);
        const documents = snap.docs.map((doc) => doc.data());
        this.documents = documents;
      });
    },
    checkNavBtnStatus() {
      // this.previousButton = document.querySelector(".v-slide-group__prev i");
      // this.nextButton = document.querySelector(".v-slide-group__next i");
      // this.isPreviousBtnDisable =
      //   this.previousButton.className.includes("disabled");
      // this.isNextBtnDisable = this.nextButton.className.includes("disabled");
    },
    clickLeft() {
      const LEFT = document.getElementById("slideBack")
      LEFT.click();
      // this.checkNavBtnStatus();
    }, 
    clickRight() {
      const RIGHT =document.getElementById("slideNext")
      RIGHT.click();
      // console.log("TO RIGHT!!!");

      // this.nextButton.click();
      // this.checkNavBtnStatus();
    },
  },
};
</script>
<style lang="scss">
.insiders {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none
  }
  .v-card {
    @media screen and (min-width: 960px) {
      margin: 0 50px 0 0;
    }
  }
  .--continue-reading {
    bottom: -12px;
  }
}
</style>