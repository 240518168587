var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about my-16"},[(
      !_vm.loading &&
      _vm.documents.length &&
      _vm.documents[0].status == 1 &&
      _vm.$route.params.slug
    )?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-img',{staticClass:"mb-12 mr-12 hidden-sm-and-down",staticStyle:{"float":"left"},attrs:{"width":"40%","src":_vm.documents[0].coverage}}),_c('v-img',{staticClass:"mb-12 mr-12 hidden-md-and-up",attrs:{"width":"100%","src":_vm.documents[0].coverage}}),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"mr-3 font-weight-bold text-uppercase",attrs:{"label":"","color":"#212121","to":("/media-insiders/tag/" + tag)}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-pound")]),_vm._v(_vm._s(tag))],1)}),1),_c('div',{staticClass:"text-h3 my-12",domProps:{"innerHTML":_vm._s(_vm.documents[0].title)}}),_c('div',{staticClass:"text-p",domProps:{"innerHTML":_vm._s(_vm.documents[0].subtitle)}})],1)],1)],1):_vm._e(),(!_vm.loading && _vm.documents.length && _vm.$route.params.tag)?_c('v-container',[_c('div',{staticClass:"text-h2 my-12"},[_c('v-icon',{staticClass:"mr-3",attrs:{"large":""}},[_vm._v("mdi-pound")]),_vm._v(_vm._s(_vm.$route.params.tag))],1),_c('v-row',_vm._l((_vm.documents),function(doc){return _c('v-col',{key:doc.id,attrs:{"cols":"12","md":"4"}},[_c('post',{attrs:{"document":doc}})],1)}),1)],1):_vm._e(),(
      !_vm.loading &&
      _vm.documents.length &&
      !_vm.$route.params.tag &&
      !_vm.$route.params.slug
    )?_c('v-container',[_c('div',{staticClass:"d-flex flex-wrap pb-12"},_vm._l((_vm.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"mr-3 font-weight-bold text-uppercase",attrs:{"color":"#212121","label":"","to":("/media-insiders/tag/" + tag)}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-pound")]),_vm._v(_vm._s(tag))],1)}),1),_c('v-row',_vm._l((_vm.documents),function(doc){return _c('v-col',{key:doc.id,attrs:{"cols":"12","md":"4"}},[_c('post',{attrs:{"document":doc}})],1)}),1)],1):_vm._e(),(_vm.loading)?_c('v-container',[_c('v-skeleton-loader',{staticClass:"my-6",attrs:{"type":"heading"}}),_c('v-row',[_c('v-col',[_c('v-skeleton-loader',{staticClass:"mt-3",attrs:{"type":"card"}})],1),_c('v-col',[_c('v-skeleton-loader',{staticClass:"mt-3",attrs:{"type":"card"}})],1),_c('v-col',[_c('v-skeleton-loader',{staticClass:"mt-3",attrs:{"type":"card"}})],1)],1)],1):_vm._e(),(
      !_vm.loading &&
      _vm.documents.length &&
      (_vm.$route.params.tag || _vm.$route.params.slug)
    )?_c('div',{staticClass:"my-6"},[_c('v-container',[_c('v-btn',{attrs:{"color":"white","light":"","x-large":"","to":"/media-insiders"}},[_vm._v("Ver outros posts "),_c('v-icon',{staticClass:"ml-9",attrs:{"small":"","right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }