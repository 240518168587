<template>
  <div>
      <v-container>
          <div class="text-h2 pt-12 text-center" v-html="$t('pages.contact_us.title')"></div>
          <div class="text-h5 pt-10 pb-16 text-center" v-html="$t('pages.contact_us.subtitle')"></div>
          <v-row class="pt-12">
              <v-col cols="12" md="4" class="pr-md-16">
                  <div class="text-p font-weight-bold mb-3" v-html="$t('pages.contact_us.where_are_we')"></div>
                  <div class="text-caption mb-9" v-html="$t('pages.contact_us.address')"><br>São Paulo - SP - CEP 05422-030</div>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.0060312739874!2d-46.69617148524023!3d-23.568226867730512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce57a0a4f600bb%3A0x65fa01de00fe5914!2sR.%20Cl%C3%A1udio%20Soares%2C%2072%20-%20Pinheiros%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005422-030!5e0!3m2!1spt-BR!2sbr!4v1646317167360!5m2!1spt-BR!2sbr" width="100%" height="150" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                  <div class="text-caption mt-9 mb-1"><v-icon small class="mr-3">mdi-phone</v-icon> +55 11 4324-2739</div>
                  <div class="text-caption"><v-icon small class="mr-3">mdi-email</v-icon> {{  $t('pages.contact_us.email') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                  <ContactForm></ContactForm>
              </v-col>
          </v-row>
      </v-container>
  </div>
</template>
<script>
import ContactForm from '@/components/ContactUs/ContactForm.vue';
export default {
  components: { ContactForm },
  data() {
    return {}
  },
};
</script>