<template>
  <div class="d-flex align-center --welcome">
    <v-container class="vh-100 d-flex align-center">
      <v-row>
        <v-col class="d-flex flex-column align-start justify-center">
          <div
            class="transition-swing text-h2 font-weight-medium" style="line-height: 1.25"
            v-html="$t('pages.home.welcome.title')" 
          ></div>
        
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-img src="@/assets/images/welcome-cube.min.gif" max-width="500" class="hidden-sm-and-down"></v-img>
          <v-img src="@/assets/images/welcome-cube.min.gif" width="300" class="hidden-md-and-up"></v-img>
        </v-col>
      </v-row>
      <div class="--scroll" :class="{ '--invisible': scrollPosition > 50 }">
        <div class="--wheel d-flex justify-center align-center">
          <v-icon>mdi-arrow-down</v-icon>
        </div>
      </div>
    </v-container>
  </div>
</template>
 
<script>
export default {
  name: "Welcome",

  data() {
    return {
    };
  },
  props: {
    scrollPosition: Number,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
  },

};
</script>
<style scoped lang="scss">
.--welcome {
  margin-top: 0; // -64px;
  position: relative;
  background: #000;
}
.--scroll {
  left: 50%;
  margin-left: 15px;
  width: 30px;
  height: 60px;
  position: fixed;
  bottom: 30px;
  border-radius: 15px;
  border: 2px solid #fff;
  transition: 2s all;
  .--wheel {
    position: absolute;
    left: -2px;
    bottom: -2px;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 15px;

    animation-duration: 2s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  &.--invisible {
    opacity: 0;
  }
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
