<template>
  <v-app-bar
    class="transition-swing border-bottom"
    app
    style="background: #212121 !important"
    elevation="0"
    :position="position"
    :hide-on-scroll="hideOnScroll"
  >
    <!-- :light="dark" -->
    <div class="d-flex align-center">
      <v-btn
        tile
        x-large
        class="pa-0 ma-0 mt-n2 mr-12 logo"
        light
        elevation="0"
        to="/"
        style="height: 50px !important; background-color: rgba(0, 0, 0, 0)"
      >
        <v-img
          to="/"
          alt="Vuetify Logo"
          contain
          src="@/assets/images/logo.svg"
          height="60"
        ></v-img>
      </v-btn>
    </div>

    <!-- <v-spacer></v-spacer> -->

    <v-btn to="/" text class="hidden-sm-and-down">
      Institucional <v-icon class="ml-3" small>mdi-arrow-right</v-icon>
    </v-btn>
    <div class="d-flex justify-end align-center --menu">
      <v-btn to="/" text class="hidden-sm-and-down">
        {{ $user.displayName }}
      </v-btn>
      <v-btn @click="logout()" text class="hidden-sm-and-down"> Sair </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on"
            ><v-img
              :src="langs[$i18n.locale]"
              height="20"
              width="30"
              contain
            ></v-img>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(lang, i) in langs"
            :key="i"
            @click="changeLang(i)"
          >
            <v-list-item-title class="d-flex align-center"
              ><v-img
                :src="lang"
                height="14"
                width="21"
                class="mr-3"
                contain
              ></v-img
              ><span style="text-transform: uppercase">{{
                i
              }}</span></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-app-bar-nav-icon
        @click="$emit('toggle', true)"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
    </div>
  </v-app-bar>
</template>
<script>
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "AppHeader",
  data() {
    return {
      langs: {
        "en-US": require("@/assets/images/countries/en-US.svg"),
        "pt-BR": require("@/assets/images/countries/pt-BR.svg"),
      },
    };
  },
  props: {
    position: String,
    hideOnScroll: Boolean,
    dark: Boolean,
  },
  created() {
    const localeDefault = this.$i18n.locale;
    const locale = this.getCookie("TMT[lang]");
    if (locale != localeDefault && locale) {
      this.changeLang(locale);
    }
  },
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$router.replace("/investors/login?do=logout");
        })
        .catch((error) => {
          console.error("error", error);
          // An error happened.
        });
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.setCookie("TMT[lang]", lang, 180);
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
};
</script>
<style scoped lang="scss">
.--menu {
  flex: 1 1 50%;
}
.border-bottom {
  background: #000 !important;
  box-shadow: 0 1px 0 #ffffff33 !important;
}
</style>