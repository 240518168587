<template>
  <div
    class="d-flex flex-column"
    style="min-height: 100vh; width: 100vw; background: #131313"
  >
    <div>
      <router-link to="/"
        ><v-img
          src="@/assets/images/logo.svg"
          class="mx-auto my-6"
          contain
          height="75"
        ></v-img
      ></router-link>
    </div>
    <v-card width="480px" class="mx-auto px-6 py-3" elevation="6" outlined>
      <v-card-title class="justify-center py-6"
        >Login into your account</v-card-title
      >
      <v-card-text>
        <div class="py-2"><label>E-mail</label></div>
        <v-text-field
          v-model="email"
          outlined
          placeholder="E-mail"
          dense
        ></v-text-field>
        <label class="d-flex py-2 justify-space-between"
          >Password<router-link to="/investors/lost-password"
            >Forgot password?</router-link
          ></label
        >
        <v-text-field
          v-model="password"
          outlined
          type="password"
          placeholder="Password"
          dense
        ></v-text-field>
        <v-btn color="accent" x-large block @click="submit()">Login now</v-btn>
        <div class="d-flex py-3 align-center mx-n3">
          <v-divider></v-divider>
          <span class="px-1 text-caption">OR</span>
          <v-divider></v-divider>
        </div>
        <v-btn color="white" x-large light block @click="googleAuth()"
          ><v-icon class="mr-6">mdi-google</v-icon> Continue with Google
        </v-btn>
        <div class="pb-9 pt-6">
          Not registered yet?
          <router-link text to="/investors/signup" class="pa-0"
            >Register
            <v-icon small class="ml-3">mdi-arrow-right</v-icon></router-link
          >
        </div>
      </v-card-text>
    </v-card>
    <div class="pa-3"></div>
  </div>
</template>
<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    submit() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          // Signed in
          this.$router.replace("/investors/app");

          // ...
        })
        .catch((error) => {
          console.error("error on update user", error);
        });
    },
    googleAuth() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          console.log(token);
          // The signed-in user info.
          const user = result.user;
          this.user = user;
          this.$router.replace("/investors/app");

          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          console.error("errorMessage", errorMessage);

          console.error("errorCode", errorCode);

          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.error("credential", credential);
          // ...
        });
    },
  },
};
</script>

