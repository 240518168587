<template>
  <div class="careers d-flex pb-16">
    <v-container>
      <div class="mx-auto py-6 mb-12">
        <div class="text-h3" v-html="$t('pages.careers.title')"></div>
        <!--  px-4 -->

        <v-toolbar light color="grey lighten-3" class="mb-3 mt-6 pr-0">
          <v-row>
            <v-col class="d-flex align-center">
              <v-text-field
                outlined
                dense
                hide-details
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                :label="$t('pages.careers.search.field')"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex align-center pr-0">
              <v-select
                v-model="department"
                dense
                outlined
                hide-details
                :items="departments"
                :label="$t('pages.careers.search.department')"
              ></v-select>
              <v-btn
                @click="clear()"
                :disabled="disabled"
                class="red--text ml-1"
                elevation="0"
                icon
                text
                ><v-icon>mdi-trash-can-outline</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <v-row v-if="!loaded">
        <v-skeleton-loader class="mt-3" type="heading"></v-skeleton-loader>
        <v-skeleton-loader class="my-3" type="heading"></v-skeleton-loader>
        <v-skeleton-loader class="mb-3" type="heading"></v-skeleton-loader>
      </v-row>
      <v-row v-if="loaded" class="flex-column-reverse flex-md-row">
        <v-col class="pb-6" cols="12" md="6" sm="12">
          <v-card
            :color="item.id === selected ? 'secondary' : ''"
            v-ripple
            v-for="(item, index) in filteredKeys"
            :key="index"
            @click="selected = item.id"
            class="mb-6"
          >
            <v-scale-transition>
              <v-card-subtitle>
                <v-row>
                  <v-col md="9">{{ item.title }}</v-col>
                  <v-col md="3">{{ loadLocations(item.location) }}</v-col>
                </v-row>
              </v-card-subtitle>
            </v-scale-transition>
          </v-card>

          <template v-if="!filteredKeys.length && !loading">
            <v-alert
              dense
              type="error"
              class="mt-6"
              v-if="search.length || department.length"
              v-html="$t('pages.careers.search.no_jobs_found_by_search')"
            ></v-alert>
            <v-alert
              v-else
              dense
              class="mt-6"
              type="info"
              v-html="$t('pages.careers.search.no_jobs_found')"
            ></v-alert>
          </template>
        </v-col>
        <v-col>
          <v-slide-x-transition>
            <v-card v-show="$route.path == '/careers/job' && job">
              <template v-if="$route.path == '/careers/job' && job">
                <v-card-title class="d-flex justify-space-between">
                  {{ job.title }}
                  <!-- <v-btn fab x-small elevation="0" @click="selected = -1"
                    ><v-icon>mdi-close</v-icon></v-btn
                  > -->
                </v-card-title>
                <v-card-subtitle class="pt-3">
                  <v-divider></v-divider>

                  <div class="d-flex justify-space-between py-3">
                    <div class="d-flex align-center">
                      <div class="text-p mr-2" v-if="job.location.length">
                        <v-icon small color="primary"
                          >mdi-map-marker-outline</v-icon
                        >{{ loadLocations(job.location) }}
                      </div>
                      <div class="text-p mr-2" v-if="job.startDay">
                        <v-icon small color="primary"
                          >mdi-clock-time-two-outline</v-icon
                        >{{ formatDate(job.startDay) }}
                      </div>
                    </div>
                    <v-btn color="green" :to="`/careers/job/${job.id}`"
                      >Candidatar-me
                      <v-icon small class="ml-3">mdi-arrow-right</v-icon></v-btn
                    >
                  </div>
                  <v-divider></v-divider>
                </v-card-subtitle>
                <v-card-text>
                  <div class="text-h6">Descrição da vaga</div>
                  <div class="description" v-html="job.description"></div>
                  <div class="text-h6">Pré-requisitos</div>
                  <div class="description" v-if="job.requirements.length">
                    <ul>
                      <li
                        v-for="(item, index) in job.requirements"
                        :key="index"
                        v-html="item"
                      ></li>
                    </ul>
                  </div>
                  <div class="text-h6">Diferenciais</div>
                  <div class="description" v-if="job.wishList.length">
                    <ul>
                      <li
                        v-for="(item, index) in job.wishList"
                        :key="index"
                        v-html="item"
                      ></li>
                    </ul>
                  </div>
                </v-card-text>
              </template>
            </v-card>
          </v-slide-x-transition>

          <!-- <v-expand-x-transition>
          <v-banner v-show="$route.path != '/careers/job' || !job" color="secondary">
            <div class="text-h6" v-html="$t('pages.careers.banner')">
            </div>
          </v-banner>
        
          </v-expand-x-transition> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import { orderBy } from "firebase/firestore";

export default {
  data() {
    return {
      selected: -1,
      filteredKeys: [],
      loaded: false,
      loading: false,
      itemsPerPageArray: [4, 8, 12],
      search: "",
      filter: {},
      department: "",
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: "title",
      keys: {
        Cargo: "title",
        Departamento: "department",
        Localização: "location",
      },
      labels: ["Cargo", "Departamento", "Localização"],

      items: [
        {
          title: "Analista de TI",
          department: "TI",
          location: "São Paulo, SP",
        },
        {
          title: "Estágio em Social Media",
          department: "Marketing",
          location: "São Paulo, SP",
        },
        {
          title: "Outbound Salles",
          department: "Comercial",
          location: "São Paulo, SP",
        },
        {
          title: "Inbound Salles",
          department: "Comercial",
          location: "São Paulo, SP",
        },
        {
          title: "Contador",
          department: "Administrativo",
          location: "Remoto",
        },
      ],
      job: null,
    };
  },
  watch: {
    selected: {
      handler(value) {
        
        let select = null;
        this.filteredKeys.forEach((item) => {
          if (item.id == value) {
            select = item;
          }
        });
        if (!select) {
          this.$router.push("/careers");
        } else {
          if(this.$route.path != '/careers/job')
          this.$router.push("/careers/job");
        }
        this.job = select;
      },
    },
    department: {
      handler(){
        this.searchItems()
      }
    },
    search: {
      handler(){
        this.searchItems()
      }
    }
    // filteredKeys: {
    //   immediate: true,
    //   deep: true,
    //   handler(value) {
    //     console.log("new val", value);
    //     this.selected = -1;
    //     setTimeout(() => {
    //       if (value.length) this.selected = 0;
    //     }, 100);
    //   },
    // },
  },
  computed: {
    disabled() {
      if (this.search != "" || this.department != "") return false;
      return true;
    },
    widthList() {
      if (this.job == null) {
        return 6;
      } else {
        return 6;
      }
    },
    departments() {
      let result = [];
      const list = this.items; // this.search.length ? this.filteredKeys : this.items
      list.forEach((item) => {
        let dept = this.$t(
          `pages.careers.search.departments.${item.department}`
        );
        if (result.indexOf(dept) == -1) result.push(dept);
      });
      return result;
    },
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    }
  },
  mounted() {
    this.loadJobs();
  },
  methods: {
    searchItems(){
      let sel = this.selected
      let hasSel = false
      let search = this.search.toLowerCase();
      let department = this.department;
      let result1 = [];
      let result2 = [];
      this.items.forEach((item) => {
        this.job = null
        if (!department || department == item.department) result1.push(item);
      });
      // console.log("result1", result1);
      // let index = 0;
      result1.forEach((item) => {
        let location = [];
        if (item.location)
          item.location.forEach((loc) => {
            location.push(this.$t(`jobs.location.${loc}`));
          });
        location = location.join("");

        if (
          !this.search ||
          item.department.toLowerCase().indexOf(search) > -1 ||
          location.toLowerCase().indexOf(search) > -1 ||
          item.title.toLowerCase().indexOf(search) > -1
        ) {
          if(sel == item.id){
            hasSel = true
          }
          result2.push(item);
        }
        // console.log('res', result2)
        // if (index == 0 && result2.length) {
        //   this.selected = result2[0].id;
        //   this.job = result2[0]
        // }
        // else{
        //   this.selected = null
        //   this.job = null
        // }
        // index++;
      });
      if(!hasSel && result2.length > 0){
        // this.job = result2[0]
        this.selected = result2[0].id
      }
      if(this.selected && result2.length > 0){
        result2.forEach((res, index) => {
          if(res.id == this.selected)
          this.job = result2[index]
        })
      }
      this.filteredKeys = result2;
    },
    formatDate(date) {
      if (!date) return "-";
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    loadLocations(locations) {
      let text = [];
      locations.forEach((loc) => {
        text.push(this.$t(`jobs.location.${loc}`));
      });
      return text.join(", ");
    },
    clear() {
      this.department = "";
      this.search = "";
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    loadJobs() {
      this.loaded = false;
      const q = this.$query(
        this.$collection(this.$db, "Jobs"),
        this.$where("status", "==", 1),
        orderBy("createdOn", "desc")
      );
      const querySnapshot = this.$getDocs(q);
      querySnapshot.then((snap) => {
        // console.log("snap", snap);
        const documents = snap.docs.map((doc) => {
          let res = doc.data();
          res.id = doc.id;
          return res;
        });
        this.items = documents;
        // if(this.items.length > 0){
        //   this.selected = this.items[0].id
        //   this.job = this.items[0]
        // }
        this.searchItems()
        this.loaded = true;
      });
    },
  },
};
</script>
 
    