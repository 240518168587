<template>
  <div class="about mb-12">
    <v-container v-if="$route.path == '/investors'">
      <div
        class="text-h2 my-12 text-center font-medium"
        v-html="$t('pages.investors.title')"
      ></div>
      <div
        class="text-p text-center mx-auto" style="max-width: 680px"
        v-html="$t('pages.investors.subtitle')"
      ></div>
         <div class="d-flex py-12">
        <div class="sibbling-cube"></div>
        <v-img
          src="@/assets/images/cube.svg"
          height="50"
          width="50"
          contain
        ></v-img>
      </div>
      <div class="d-flex justify-center flex-column mb-12 pb-12">
        <div
          class="text-p mb-12 text-center"
          v-html="$t('pages.investors.are_you_investor')"
        ></div>
        <v-btn
          x-large
          color="secondary"
          class="mx-auto"
                   target="_blank" href="https://ri.themediatrade.com">
         <!-- to="/investors/app" -->
        {{ $t('pages.investors.access') }}
          <v-icon class="ml-9" small>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
      <v-row class="my-12">
        <v-col cols="12" md="7">
          <v-img :src="getImage($t('pages.investors.panels[0].photo'))"></v-img>
        </v-col>
        <v-col cols="12" md="5">
          <div class="pa-3 pl-9">
            <div
              class="text-h3 mb-3 font-weight-semi-bold"
              v-html="$t('pages.investors.panels[0].title')"
            ></div>
            <div
              class="text-p"
              v-html="$t('pages.investors.panels[0].text')"
            ></div>
          </div>
        </v-col>
      </v-row>
    
      <div class="d-flex text-h5 justify-center pb-3">Partners</div>
      <div class="d-flex justify-center align-center pb-16">
        
        <div class="py-0 px-6"
          >
          <v-img
            src="@/assets/images/companies/partners/tunad_color.svg"
            max-width="170"
            contain
          ></v-img>
         </div
        >
        <v-divider dark vertical></v-divider>
        <div class="py-0 px-6"
          >
          <v-img
            src="@/assets/images/companies/partners/kantar_ibope_color.svg"
            max-width="300"
            contain
          ></v-img>
         </div
        >
        <v-divider dark vertical></v-divider>
        
        <div class="py-0 px-6"
          >
          <v-img
            src="@/assets/images/espm.svg"
            max-width="100"
            contain
          ></v-img>
          
        </div>
        
      </div>
        <div class="d-flex py-12">
        <v-img
          src="@/assets/images/cube.svg"
          height="50"
          width="50"
          contain
          class="mb-12"
        ></v-img>
        <div class="sibbling-cube"></div>
      </div>
      <!-- <div class="d-flex justify-end py-12">
        <v-img
          src="@/assets/images/cube.svg"
          height="50"
          width="50"
          contain
          class="mb-12"
        ></v-img>
        <div class="sibbling-cube"></div>
      </div> -->
      <v-row class="pb-12">
        <v-col cols="12" md="5">
          <div class="pa-3 pr-9">
            <div
              class="text-h3 mb-3 font-weight-semi-bold"
              v-html="$t('pages.investors.panels[1].title')"
            ></div>
            <div
              class="text-p"
              v-html="$t('pages.investors.panels[1].text')"
            ></div>
          </div>
        </v-col>
        <v-col cols="12" md="7">
          <v-img :src="getImage($t('pages.investors.panels[1].photo'))"></v-img>
        </v-col>
      </v-row>
      <v-row>
       
        <v-col class="py-0"
          ><p class="mb-2 text-center">{{ $t("pages.investors.seals.fcpa.title") }}</p>
          <v-img
            src="@/assets/images/fcpa.svg"
            contain
            class="mx-auto"
            max-width="160"
            max-height="60"
          ></v-img>
           <p class="mb-0 text-center" v-html="$t('pages.investors.seals.fcpa.subtitle')">
          </p>
        </v-col>
        <v-divider dark vertical></v-divider>

        <v-col class="py-0"
          ><p class="mb-2 text-center">{{ $t("pages.investors.seals.dre.title") }}</p>
          <v-img
            src="@/assets/images/dre.svg"
            contain
            max-width="160"
            max-height="60"
            class="mx-auto"
          ></v-img>
           <p class="mb-0 text-center" v-html="$t('pages.investors.seals.dre.subtitle')">
          </p></v-col
        >
        <v-divider dark vertical></v-divider>

        <v-col class="py-0"
          ><p class="mb-2 text-center">{{ $t("pages.investors.seals.lgpd.title") }}</p>
          <v-img
            src="@/assets/images/lgpd.svg"
            contain
            max-width="160"
            max-height="60"
            class="mx-auto"
          ></v-img>
          <p class="mb-0 text-center" v-html="$t('pages.investors.seals.lgpd.subtitle')">
          </p></v-col
        >
      </v-row>
      <div class="d-flex justify-end py-12">
        <div class="sibbling-cube"></div>
        <v-img
          src="@/assets/images/cube.svg"
          height="50"
          width="50"
          contain
          class="mb-12"
        ></v-img>
      </div>
      <v-card color="#212121" class="mt-12">
        <div class="d-flex flex-column flex-md-row py-12 px-12 align-center">
          <div class="mr-md-6 pb-9 pb-md-0 pb-sm-9">
            <div
              class="text-h3 mb-6"
              v-html="$t('pages.investors.are_you_investor')"
            ></div>
            <div
              class="text-p"
              v-html="$t('pages.investors.are_you_investor_description')"
            ></div>
          </div>
          <!-- <v-btn color="white" x-large light to="/investors/app"> -->
          <v-btn color="white" x-large light target="_blank" href="https://ri.themediatrade.com">
            {{ $t("pages.investors.access") }}
            <v-icon class="ml-9" small color="#000">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-card>
      <div class="d-flex py-12">
        <v-img
          src="@/assets/images/cube.svg"
          height="50"
          width="50"
          contain
          class="mb-12"
        ></v-img>
        <div class="sibbling-cube"></div>
      </div>
      <div
        class="text-h3 text-center my-12"
        v-html="$t('pages.investors.get_in_touch')"
      ></div>
      <div
        class="text-p text-center"
        v-html="$t('pages.investors.get_in_touch_description')"
      ></div>
      <v-row class="pa-6 mx-md-12">
        <!-- <v-col class="px-6" cols="12" md="4" sm="12">
          <v-card color="#212121" class="pa-6">
            <div class="text-p">{{ $t("pages.investors.contact.title") }}:</div>
            <div class="text-caption">
              {{ $t("pages.investors.contact.name") }}
            </div>
            <div class="text-caption">
              <v-icon class="mr-3" x-small>mdi-phone</v-icon>
              {{ $t("pages.investors.contact.phone") }}
            </div>
            <div class="text-caption">
              <v-icon class="mr-3" x-small>mdi-email</v-icon>
              {{ $t("pages.investors.contact.email") }}
            </div>
          </v-card>
        </v-col> -->
        <v-col class="px-md-6 mx-auto" md="8" sm="12">
          <ContactForm :subject-index="4"></ContactForm>
        </v-col>
      </v-row>
    </v-container>
    <router-view />
  </div>
</template>
<script>
import ContactForm from "@/components/ContactUs/ContactForm";
export default {
  name: "Investors",
  components: {
    ContactForm,
  },
  methods: {
    getImage(src) {
      return require(`@/assets/images/${src}`);
    },
  },
};
</script>
<style lang="scss">
.about {
  .theme--dark.v-divider {
    border-color: rgba(255, 255, 255, 0.32);
    margin-left: 12px;
    margin-right: 12px;
  }
  .sibbling-cube {
    flex-basis: calc(100% - 50px);
  }
}
</style>