<template>
  <div style="width: 100%" class="pa-6">
    <div class="text-h4 pb-3">Documentos institucionais</div>
    <v-data-table
      style="width: 100%"
      :headers="headers"
      :items="documents"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.date="{ item }">
        {{ formatDate(item.date) }}
      </template>
       <template v-slot:item.actions="{ item }">
        <span class="d-flex">
          <v-icon small class="mr-2" @click="download(item.file)">
            mdi-download
          </v-icon>
        </span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { collection, query, where, getDocs } from "firebase/firestore";
import moment from "moment";
export default {
  data() {
    return {
      headers: [
                { text: "#", value: "index" },

        {
          text: "Título do arquivo",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Data do documento", value: "date" },
        { text: "", value: "actions", sortable: false },
      ],
      documents: [],
      now: moment().format()
    };
  },
  mounted() {
    this.loadDocuments();
  },
  methods: {
    formatDate(date){
      return moment(date,'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    loadDocuments() {
      let documents = [];
      this.loading = true;
      const q = query(
        collection(this.$db, "Documents"),
        where("category", "==", "Institutional")
        // where("status", "==", 1)
      );

      const querySnapshot = getDocs(q);
      querySnapshot.then((snap) => {
        documents = snap.docs.map((doc, index) => {
            let d = doc.data()
            d.index = index+1
            return d
        } 
        );
        this.documents = documents;
        this.loading = false;
      });
    },
    download(url) {
      var link = document.createElement("a");
      link.href = url;
      link.download = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
