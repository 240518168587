<template>
  <div class="job-subscription py-12">
    <v-container v-if="loading">
      <v-skeleton-loader class="my-3" type="chip"></v-skeleton-loader>
      <v-skeleton-loader
        max-width="120"
        class="mb-16"
        type="text@1"
      ></v-skeleton-loader>
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </v-container>
    <v-container v-else>
      <div class="text-h3">{{ job.title }}</div>
      <!--  px-4 -->
      <div class="text-h6">{{ locations }}</div>
      <!--  px-4 -->
      <v-spacer></v-spacer>
      <div class="mt-6 py-6">
        <!-- <div class="text-h6 pa-4"> Informações Pessoais</div> -->
        <fieldset class="mb-6">
          <legend>Informações Pessoais</legend>
          <v-row>
            <v-col>
              <v-text-field
                v-model="name"
                dense
                :error-messages="nameErrors"
                label="Nome"
                required
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                outlined
                counter="50"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="email"
                dense
                :error-messages="emailErrors"
                label="E-mail"
                required
                counter="50"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="phone"
                dense
                :error-messages="phoneErrors"
                label="Telefone"
                required
                @input="$v.phone.$touch()"
                @blur="$v.phone.$touch()"
                v-mask="['(##) ####-####', '(##) #####-####']"
                outlined
                counter="15"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                v-model="address"
                :error-messages="addressErrors"
                label="Endereço"
                required
                counter="50"
                @input="$v.address.$touch()"
                @blur="$v.address.$touch()"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </fieldset>
        <fieldset style="margin-top: 48px">
          <legend v-if="socialNetworks.length">Redes Sociais</legend>
          <div
            class="d-flex justify-space-between align-center"
            v-if="!socialNetworks.length"
          >
            <div class="text-body-1">Redes Sociais</div>
            <v-btn @click="socialAdd()" color="primary" outlined
              ><v-icon>mdi-plus</v-icon> Adicionar</v-btn
            >
          </div>
          <v-list v-if="socialNetworks.length">
            <v-list-item
              v-for="(item, index) in socialNetworks"
              :key="index"
              class="mb-3"
            >
              <v-list-item-content style="width: 100%">
                <social-network
                  :snap="item"
                  @update="socialUpdate(index, $event)"
                  @error="addError(index, $event)"
                >
                  <template v-slot:actions>
                    <v-btn
                      @click="remove(index)"
                      class="red--text"
                      elevation="0"
                      fab
                      ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </template>
                </social-network>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="d-flex justify-end" v-if="socialNetworks.length">
            <v-btn
              @click="socialAdd()"
              class="mt-3"
              color="primary"
              outlined
              :disabled="socialNetworks.length == 5"
              ><v-icon>mdi-plus</v-icon> Adicionar</v-btn
            >
          </div>
        </fieldset>
        <div class="d-flex justify-end text-caption pt-1 pr-3 pb-6">
          {{ socialNetworks.length }}/5
        </div>

        <!-- <fieldset>
          <legend v-if="experience.length">Educação</legend>
          <div
            class="d-flex justify-space-between align-center"
            v-if="!education.length"
          >
            <div class="text-body-1">Educação</div>
            <v-btn @click="educationAdd()" color="primary" outlined
              ><v-icon>mdi-plus</v-icon> Adicionar</v-btn
            >
          </div>
          <v-list v-if="education.length">
            <v-list-item
              v-for="(item, index) in education"
              :key="index"
              class="mb-3"
            >
              <v-list-item-content style="width: 100%">
                <education
                  :snap="item"
                  @update="educationUpdate(index, $event)"
                  @error="educationError(index, $event)"
                  @remove="removeEducation(index, $event)"
                  @force-remove="removeEducation(index, $event, true)"
                >
                </education>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="d-flex justify-end" v-if="education.length">
            <v-btn @click="educationAdd()" color="primary" outlined
              ><v-icon>mdi-plus</v-icon> Adicionar</v-btn
            >
          </div>
        </fieldset>
        <div class="d-flex justify-end text-caption pt-1 pr-3 pb-6">
          {{ education.length }}/5
        </div> -->

        <!-- <fieldset>
          <legend v-if="experience.length">Experiência</legend>
          <div
            class="d-flex justify-space-between align-center"
            v-if="!experience.length"
          >
            <div class="text-body-1">Experiência</div>
            <v-btn @click="experienceAdd()" color="primary" outlined
              ><v-icon>mdi-plus</v-icon> Adicionar</v-btn
            >
          </div>
          <v-list v-if="experience.length">
            <v-list-item
              v-for="(item, index) in experience"
              :key="index"
              class="mb-3"
            >
              <v-list-item-content style="width: 100%">
                <experience
                  :snap="item"
                  @update="experienceUpdate(index, $event)"
                  @error="experienceError(index, $event)"
                  @remove="removeExperience(index, $event)"
                  @force-remove="removeExperience(index, $event, true)"
                >
                </experience>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="d-flex justify-end" v-if="experience.length">
            <v-btn @click="experienceAdd()" color="primary" outlined
              ><v-icon>mdi-plus</v-icon> Adicionar</v-btn
            >
          </div>
        </fieldset>

        <div :class="experiencePadding">{{ experience.length }}/5</div> -->
        <fieldset>
          <legend v-if="cv.length">Currículo / CV</legend>
          <div
            class="d-flex justify-space-between align-center"
            style="position: relative"
            v-if="!cv.length"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              ref="cv"
              @change="onChange"
              accept=".pdf,.doc,.docx,.rtf"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% + 24px);
                margin: -12px;
                height: calc(100% + 24px);
                background: #f00;
                opacity: 0;
                cursor: pointer;
              "
              title=""
            />
            <div class="text-body-1">Envie seu currículo / CV</div>
            <v-btn @click="cvAdd()" color="primary" outlined
              ><v-icon>mdi-plus</v-icon> Adicionar</v-btn
            >
          </div>
          <div v-if="cv.length">
            <v-card width="100%" outlined>
              <v-card-text>
                <div class="d-flex justify-space-between align-center">
                  <div class="text-h5">
                    {{ cvInfo[0].name }} ({{ formatBytes(cvInfo[0].size) }})
                  </div>
                  <div class="d-flex">
                    <v-btn
                      @click="removeCv()"
                      elevation="0"
                      fab
                      class="red--text mx-3"
                      ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </fieldset>
        <div class="d-flex justify-end text-caption pt-1 pr-3 pb-6">
          {{ cvInfo.length }}/1
        </div>
        <v-row class="mt-n6">
          <v-col>
            <fieldset>
              <legend>Informações adicionais</legend>
              <v-textarea
                dense
                v-model="message"
                label="Escreva sobre seu interesse em trabalhar conosco."
                required
                :error-messages="messageErrors"
                counter="300"
                height="105"
                @input="$v.message.$touch()"
                @blur="$v.message.$touch()"
                outlined
              ></v-textarea>
            </fieldset>
          </v-col>
          <v-col>
            <fieldset>
              <legend>Onde ou como você conheceu a Media Trade?</legend>
              <v-select
                dense
                outlined
                :error-messages="howDidYouMeetUsErrors"
                @input="$v.howDidYouMeetUs.$touch()"
                @blur="$v.howDidYouMeetUs.$touch()"
                v-model="howDidYouMeetUs"
                :items="list"
              ></v-select>
              <v-text-field
                v-if="howDidYouMeetUs == 'Outro'"
                dense
                v-model="otherReason"
                label="Nos diga como ou onde nos conheceu, por favor."
                required
                counter="50"
                :error-messages="otherReasonErrors"
                @input="$v.otherReason.$touch()"
                @blur="$v.otherReason.$touch()"
                outlined
              ></v-text-field>
            </fieldset>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex justify-end py-4">
        <v-btn
          x-large
          color="green"
          @click="submit()"
          :disabled="$v.$invalid"
          :loading="submiting"
          >Enviar <v-icon small class="ml-6">mdi-arrow-right</v-icon></v-btn
        >
      </div>
    </v-container>
    <v-dialog v-model="success" max-width="480">
      <v-card type="success" color="#12c06a" class="py-9 px-7">
        <v-card-subtitle>
          <v-img
            src="@/assets/images/success.gif"
            class="relative"
            height="180"
          ></v-img>
          <div class="text-h5 mb-6 text-center relative evidence">
            Você foi inscrito na vaga com <strong>sucesso</strong>!
          </div></v-card-subtitle
        >
        <v-card-text
          ><div class="text-p">
            Vamos analisar suas informações e seu currículo cuidadosamente e,
            assim que possível, entraremos em contato com você!
          </div>
          <div class="text-p"><strong>Boa sorte!</strong></div></v-card-text
        >
        <v-card-actions
          ><v-btn outlined @click="success = false" x-large class="mx-auto"
            >Voltar</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
// import Education from "@/components/Jobs/Education.vue";
// import Experience from "@/components/Jobs/Experience.vue";
import SocialNetwork from "@/components/Jobs/SocialNetwork.vue";
import moment from "moment";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: "Job",
  components: {
    // Education,
    // Experience,
    SocialNetwork,
  },
  mixins: [validationMixin],
  validations() {
    return {

      email: { required, email },
      name: { required, minLength: minLength(4), maxLength: maxLength(30) },
      address: { required, minLength: minLength(4), maxLength: maxLength(50) },
      phone: { required, minLength: minLength(14) },
      message: { maxLength: maxLength(300) },
      howDidYouMeetUs: { required },
      otherReason:
        this.howDidYouMeetUs == "Outro" ? { maxLength: maxLength(50) } : {},
    }
  },
  data() {
    return {
      success: false,
      submiting: false,
      loading: true,
      job: null,
      message: "",
      name: "",
      email: "",
      phone: "",
      mobile: "",
      address: "",
      socialNetworks: [],
      // education: [],
      // experience: [],
      socialEditing: [],
      hasError: [],
      hasXPError: [],
      cv: [],
      cvInfo: [],
      list: [
        "Indicação de amigos",
        "Pela minha rede de contatos (network)",
        "Na minha faculdade",
        "Já fiz negócios com vocês",
        "Pesquisa na Internet",
        "Pelas redes sociais",
        "Por sites de vagas de emprego",
        "Outro",
      ],
      howDidYouMeetUs: "",
      otherReason: "",
    };
  },
  computed: {
    locations() {
      let text = [];
      if (!this.job) return "";
      this.job.location.forEach((loc) => {
        text.push(this.$t(`jobs.location.${loc}`));
      });
      return text.join(", ");
    },
    experiencePadding() {
      if (this.cv.length)
        return "d-flex justify-end text-caption pt-1 pr-3 pb-3";
      else return "d-flex justify-end text-caption pt-1 pr-3 pb-6";
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Digite seu nome.");
      !this.$v.name.minLength &&
        errors.push("Seu nome deve conter ao menos 3 caracteres.");
      !this.$v.name.maxLength &&
        errors.push("Seu nome deve conter no máximo 50 caracteres.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required && errors.push("Digite seu endereço.");
      !this.$v.address.minLength &&
        errors.push("Seu endereço deve conter ao menos 3 caracteres.");
      !this.$v.address.maxLength &&
        errors.push("Seu endereço deve conter no máximo 50 caracteres.");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;

      !this.$v.message.maxLength &&
        errors.push("Seu endereço deve conter no máximo 300 caracteres.");
      return errors;
    },
    howDidYouMeetUsErrors() {
      const errors = [];
      if (!this.$v.howDidYouMeetUs.$dirty) return errors;

      !this.$v.howDidYouMeetUs.required && errors.push("Escolha uma opção.");
      return errors;
    },
    otherReasonErrors() {
      const errors = [];
      if (this.howDidYouMeetUs !== "Outro") {
        return errors;
      }
      if (!this.$v.otherReason.$dirty) return errors;
      !this.$v.otherReason.maxLength &&
        errors.push("O motivo deve conter no máximo 50 caracteres.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Digite seu telefone.");
      !this.$v.phone.minLength && errors.push("Digite um telefone válido");

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Digite um e-mail válido.");
      !this.$v.email.required && errors.push("Digite seu e-mail.");
      return errors;
    },
  },
  watch: {
    cv: {
      deep: true,
      handler(value) {
        let f = value[0];

        let reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = ((theFile) => {
          this.cvInfo = [theFile];
        })(f);

        // Read in the image file as a data URL.
        reader.readAsText(f);
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.load();
    }, 3000);
  },
  methods: {
    load() {
      this.loading = true;
      const docRef = this.$doc(this.$db, "Jobs", this.$route.params.job);
      const docSnap = this.$getDoc(docRef);
      docSnap.then((snap) => {
        console.log("docSnap", snap.data());
        if (snap.exists()) {
          const { title, createdOn, location, department } = snap.data();
          this.job = {
            title,
            createdOn,
            location,
            department,
          };
          this.loading = false;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          this.loading = false;
        }
      });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    removeCv() {
      this.cv = [...[]];
      this.cvInfo = [...[]];
    },
    onChange() {
      this.cv = [...this.$refs.cv.files];
    },
    removeFile(i) {
      this.cv.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.cv.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    cvAdd() {
      this.$refs.cv.click();
    },
    addError(index, event) {
      this.hasError[index] = event;
    },
    educationError(index, event) {
      this.hasError[index] = event;
    },
    experienceError(index, event) {
      this.hasXPError[index] = event;
    },
    socialEdit(index) {
      if (this.socialEditing.includes(index)) {
        this.socialEditing.splice(index, 1);
      } else {
        this.socialEditing.push(index);
      }
    },
    socialAdd() {
      if (this.socialNetworks.length < 5) {
        this.socialNetworks.push("");
        this.socialEditing.push(this.socialNetworks.length - 1);
      }
    },
    educationAdd() {
      if (this.education.length < 5) {
        this.education.push({
          institution: "",
          major: "",
          degree: "",
          location: "",
          description: "",
          from: "",
          to: "",
          current: false,
          editing: true,
        });
      }
    },
    experienceAdd() {
      if (this.experience.length < 5) {
        this.experience.push({
          title: "",
          location: "",
          company: "",
          description: "",
          from: "",
          to: "",
          current: false,
          editing: true,
        });
      }
    },
    socialUpdate(index, event) {
      // console.log("index", index);
      // console.log("event", event);
      this.socialNetworks[index] = event;
      this.socialEditing.splice(this.socialEditing.indexOf(index), 1);
    },
    educationUpdate(index, event) {
      // console.log("event", event);
      this.education[index] = event;
      this.education[index].editing = false;
    },
    experienceUpdate(index, event) {
      // console.log("event", event);
      this.experience[index] = event;
      this.experience[index].editing = false;
    },
    remove(index) {
      this.socialNetworks.splice(index, 1);
      this.socialNetworks = [...this.socialNetworks];

      this.hasError.splice(this.hasError.indexOf(index), 1);
      this.hasError = [...this.hasError];
    },
    removeEducation(index, force = false) {
      if (force || !this.education[index].institution.length) {
        this.education.splice(index, 1);
        this.education = [...this.education];

        this.educationError.splice(this.educationError.indexOf(index), 1);
        this.educationError = [...this.educationError];
      } else {
        const SNAP = this.education[index];
        SNAP.editing = false;
        this.education[index] = [...SNAP];
      }
    },
    removeExperience(index, force = false) {
      if (force || !this.experience[index].institution.length) {
        this.experience.splice(index, 1);
        this.experience = [...this.experience];

        this.experienceError.splice(this.experienceError.indexOf(index), 1);
        this.experienceError = [...this.experienceError];
      } else {
        const SNAP = this.experience[index];
        SNAP.editing = false;
        this.experience[index] = [...SNAP];
      }
    },
    submit() {
      this.submiting = true;
      console.log("... ");
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.error(this.$v.$invalid);
      } else {
        const name = this.cvInfo[0].name;
        const extension = name.split(".").pop();
        const fileNewName = `${this.name}-${this.email}.${extension}`;
        const storage = getStorage();
        const storageRef = ref(storage, fileNewName);

        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, this.cvInfo[0]).then((snapshot) => {
          getDownloadURL(ref(storage, fileNewName)).then((cvUrl) => {
            console.log("snapshot File:", snapshot);
            const FORM = {
              address: this.address,
              email: this.email,
              education: this.education,
              cv: cvUrl,
              experience: this.experience,
              socialNetworks: this.socialNetworks,
              name: this.name,
              mobile: this.mobile,
              phone: this.phone,
              createdOn: moment().format(),
              job: this.$route.params.job,
            };
            console.log("JOB", FORM);
            // Add a new document in collection "cities"
            const insertDoc = this.$setDoc(
              this.$doc(this.$db, "Applicants", this.email),
              FORM
            );
            insertDoc.then((doc) => {
              console.log("DOC", doc);
              this.submiting = false;
              this.success = true;
            });
          });
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
fieldset {
  margin: 0;
  background: #1e1e1e;
  /* background-color: currentColor; */
  /* border-color: currentColor; */
  border-style: solid;
  border-width: thin;

  padding: 12px;

  border-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}
.job-subscription {
}
</style>