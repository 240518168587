<template>
  <div>
    <div class="relative">
      <Welcome :scroll-position="scrollPosition" ref="Welcome" />
    </div>
    <div class="relative">
      <Future ref="Future" />
    </div>
    <!-- <div ref="WeAreMediaTech">
      <WeAreMediaTech />
    </div> -->
    <div ref="Features" class="relative z-0 hidden-sm-and-down">
      <Features
        :scrollPosition="scrollPosition"
        :is-active="activeBounce == 'Feature'"
      />
    </div>
    <div ref="Features" class="relative z-0 hidden-md-and-up">
      <FeaturesMobile
        :scrollPosition="scrollPosition"
        :is-active="activeBounce == 'Feature'"
      />
    </div>
    <div ref="Solutions">
      <Solutions />
    </div>
    <div ref="Companies">
      <Companies />
    </div>
    <div ref="Insiders">
      <Insiders />
    </div>
    <div ref="WorkWithUs">
      <WorkWithUs />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Welcome from "@/components/Home/Welcome.vue";
import Future from "@/components/Home/Future.vue";
// import WeAreMediaTech from "@/components/Home/WeAreMediaTech.vue";
import Features from "@/components/Home/Features.vue";
import FeaturesMobile from "@/components/Home/FeaturesMobile.vue";
import Solutions from "@/components/Home/Solutions.vue";
import Companies from "@/components/Home/Companies.vue";
import Insiders from "@/components/Home/Insiders.vue";
import WorkWithUs from "@/components/Home/WorkWithUs.vue";

export default Vue.extend({
  name: "Home",

  components: {
    Welcome,
    // WeAreMediaTech,
    Future,
    Features,
    FeaturesMobile,
    Solutions,
    Companies,
    Insiders,
    WorkWithUs,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      scrollPosition: 0,
      activeBounce: "",
      bounces: {
        Feature: {
          top: 0,
          y: 0,
        },
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    setTimeout(() => {
      this.bounces.Feature.top =
        this.$refs.Features.getBoundingClientRect().top;
      this.bounces.Feature.y =
        this.$refs.Features.getBoundingClientRect().height;
    }, 1000);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.checkBounce("Feature");
    },
    checkBounce(key) {
      if (
        this.scrollPosition > this.bounces[key].top &&
        this.scrollPosition <
          this.bounces[key].y + this.bounces[key].top - this.window.height / 2
      ) {
        this.activeBounce = "Feature";
      } else {
        this.activeBounce = "";
      }
    },
  },
});
</script>
<style lang="scss">
.relative {
  position: relative;
  z-index: 1;
}
.z-0 {
  z-index: 0;
}
.text-h0 {
  font-size: 150px;
  line-height: 1.1;
  @media screen and (max-width: 720px) {
    font-size: 2em;
  }
}
</style>
