import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify);

export default new Vuetify(
    {
        theme: {
          themes: {
            dark: {
              primary: '#2196f3', //colors.red.darken1, // #E53935
              secondary: '#233a75',// colors.red.lighten4, // #FFCDD2
              accent: '#2A4A9D',//'colors.indigo.base', // #3F51B5,
              orange: '#f25547',
              blue: '#7f9ebb'

            },
          },
        },
      }
);
