import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth } from "firebase/auth";

import Home from '../views/Home.vue'

import Careers from '../views/Careers.vue'
import Job from '../views/Job.vue'
import Investor from '../views/Investors.vue'
import ContactUs from '../views/ContactUs.vue'
import MediaInsiders from '../views/MediaInsiders.vue'

import Login from '@/components/Investors/Login.vue'
import Signup from '@/components/Investors/Signup.vue'
import Relationship from '@/components/Investors/Relationship.vue'
import Contracts from '@/components/Investors/RI/Contracts.vue'
import Customers from '@/components/Investors/RI/Customers.vue'
import Documents from '@/components/Investors/RI/Documents.vue'
import FinancialStatements from '@/components/Investors/RI/FinancialStatements.vue'
import KPIs from '@/components/Investors/RI/KPIs.vue'
import MyAccount from '@/components/Investors/RI/MyAccount.vue'
import Publishers from '@/components/Investors/RI/Publishers.vue'
import Welcome from '@/components/Investors/RI/Welcome.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/careers',
    name: 'Careers',
    component: Careers,
  },
  {
    path: '/careers/job',
    name: 'Careers',
    component: Careers,
  },
  {
    path: '/careers/job/:job',
    name: 'Job',
    component: Job
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/media-insiders',
    name: 'MediaInsiders',
    component: MediaInsiders
  },
  {
    path: '/media-insiders/:slug',
    name: 'MediaInsiders',
    component: MediaInsiders
  },

  {
    path: '/media-insiders/tag/:tag',
    name: 'MediaInsiders',
    component: MediaInsiders
  },
  {
    path: '/investors',
    name: "Investors",
    component: Investor,
    children: [
      {
        path: 'login',
        component: Login
      },
      {
        path: 'signup',
        component: Signup
      },
      {
        path: 'app',
        component: Relationship,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'contracts',
            component: Contracts,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'customers',
            component: Customers,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'documents',
            component: Documents,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'financial',
            component: FinancialStatements,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'kpis',
            component: KPIs,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'my-account',
            component: MyAccount,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'Publishers',
            component: Publishers,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'Welcome',
            component: Welcome,
            meta: {
              requiresAuth: true
            }
          },
        ]
      }
    ]
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// export default router





// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//       return { x: 0, y: 0 }
//   }
// })

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  // const TITLE = to.matched.some(record => record.meta.title);
  // document.title = TITLE ? to.meta.title : 'Classificados - Canal do Anúncio'

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) next('/investors/login')
  else next()
});

export default router
