<template>
  <div>
    <AppInvestorHeader />

    <v-row
      no-gutters
      style="height: 100%; min-height: calc(100vh - 110px)"
      class="flex-nowrap"
    >
      <v-col>
        <v-navigation-drawer color="#303030" permanent>
          <v-list dense>
            <v-list-item
              class="px-0 justify-center"
              to="/investors/app/welcome"
            >
              <v-list-item-content class="pl-3"> Início </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-0 justify-center"
              to="/investors/app/my-account"
            >
              <v-list-item-content class="pl-3">
                Minha conta
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="px-0 justify-center" to="/investors/app/kpis">
              <v-list-item-content class="pl-3"> KPIs </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-0 justify-center"
              to="/investors/app/documents"
            >
              <v-list-item-content class="pl-3">
                Documentos
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-0 justify-center"
              to="/investors/app/financial"
            >
              <v-list-item-content class="pl-3">
                Demonstrativos
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-0 justify-center"
              to="/investors/app/contracts"
            >
              <v-list-item-content class="pl-3">
                Contratos
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-0 justify-center"
              to="/investors/app/publishers"
            >
              <v-list-item-content class="pl-3"> Veículos </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-0 justify-center"
              to="/investors/app/customers"
            >
              <v-list-item-content class="pl-3">
                Parceiros
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <div style="background: #303030; width: 100%;">
        <router-view></router-view>
      </div>
    </v-row>
  </div>
</template>
<script>
import AppInvestorHeader from "@/interfaces/AppInvestorHeader.vue";
export default {
  components: {
    AppInvestorHeader,
  },
};
</script>
