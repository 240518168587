<template>
  <div>
    <v-card width="100%" outlined>
      <v-card-text>
        <div class="d-flex align-center" style="width: 100%">
          <div style="flex: 1 1 50%" class="pr-3 pt-6">
            <v-text-field
            dense
            outlined
              v-model="link"
              :error-messages="linkErrors"
              debounce="200"
              label="URL do perfil (ex.: https://www.exemplo.com)"
              required
              @input="$v.link.$touch()"
              @blur="$v.link.$touch()"
              counter="100"
            ></v-text-field>
          </div>
          <slot name="actions"></slot>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, url, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    link: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(100),
      url,
    },
  },
  data() {
    return {
      link: "",
    };
  },
  props: {
    snap: String,
  },
  watch: {
    snap(value) {
      this.link = value;
    },
    link() {
      this.submit();
    },
  },
  mounted() {
    this.link = this.snap;
    this.$v.$touch();
  },
  computed: {
    linkErrors() {
      const errors = [];
      if (!this.$v.link.$dirty) return errors;
      !this.$v.link.required && errors.push("Digite seu nome.");
      !this.$v.link.minLength &&
        errors.push("Seu nome deve conter ao menos 4 caracteres.");
      !this.$v.link.maxLength &&
        errors.push("Seu nome deve conter no máximo 100 caracteres.");
      !this.$v.link.url && errors.push("Digite uma URL válida.");
      return errors;
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      this.$emit("error", this.$v.$invalid);
      if (this.$v.$invalid) {
        console.error(this.$v.$invalid);
      } else {
        this.$emit("update", this.link);
      }
    },
  },
};
</script>