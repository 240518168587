<template>
    <div class="solutions">
        <!-- <div class="bg"></div> -->
        <v-container>
            <v-img src="@/assets/images/cube.svg" max-width="50" contain class="mb-12"></v-img>
            <div class="text-h0" v-html="$t('pages.home.our_solutions.title')"></div>
            <!-- <v-row>
            <v-col cols="12">
            </v-col>
            <v-col></v-col>
          </v-row> -->
        </v-container>
        <!-- <div class="d-flex align-center pt-16">
          <v-container class="vh-100 d-flex flex-column align-center justify-center"> -->
        <Platforms></Platforms>
        <Tools></Tools>
        <Intelligence></Intelligence>
        <!-- </v-container>
        </div> -->
    </div>
</template>

<script>
import Platforms from "./Solutions/Platforms.vue";
import Tools from "./Solutions/Tools.vue";
import Intelligence from "./Solutions/Intelligence.vue";
export default {
    name: "Solutions",
    components: {
        Platforms,
        Tools,
        Intelligence,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.v-application .rounded-0.--custom-icon {
    border-radius: 6px !important;
}

.solutions {
    position: relative;
    background: linear-gradient( 180deg, rgba(35, 58, 117, 1) 0%, rgba(0, 0, 0, 1) 300px); // rgba(35, 58, 117, 1) 50%,
}

.bg {
    background: linear-gradient( 0deg, rgba(0, 0, 0, 1) 0%, rgba(35, 58, 117, 1) 50%, rgba(35, 58, 117, 1) 100%);
    width: 100vw;
    height: 100vh;
    margin-bottom: -100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
}
</style>