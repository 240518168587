<template>
  <v-card max-width="394" class="px-1" color="black" tile :style="styling">
    <div style="position: relative">
      <!-- lazy-src="https://picsum.photos/id/11/10/6" -->
      <v-img height="276" cover :src="document.coverage"></v-img>
      <div style="float: left">
        <v-btn
          absolute
          right
          class="--continue-reading mt-n6"
          color="white"
          elevation="2"
          light
          :to="`/media-insiders/${document.slug}`"
          >{{ $t("pages.home.media_insiders.continue_reading") }} <v-icon right>mdi-arrow-right</v-icon></v-btn
        >
      </div>
    </div>
    <v-card-title
      class="no-break-word mx-0 px-0"
      v-html="document.title"
    ></v-card-title>
    <v-card-text
      class="no-break-word three-lines mx-0 px-0"
      v-html="document.subtitle"
    ></v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Post",
  data() {
    return {
      styling: "",
    };
  },
  props: {
    document: Object,
    first: Boolean,
    last: Boolean,
    margin: Number,
  },
  mounted() {
    let style = "";
    if (this.first) {
      style += `margin-left: ${this.margin}px;`;
    }
    if (this.last) {
      style += `margin-right: ${this.margin}px `;
    }
    this.styling = style;
  },
};
</script>
<style lang="scss" scoped>
.no-break-word {
  word-break: normal;
}
.three-lines {
  width: 100%;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-justify: justify;
}
</style>